.thankyou_page {
  @extend .w_100;
  max-width: 58.4rem;
  @extend .m_auto;
  @include media_query(two_extra_large) {
    padding: 5rem 2rem 0 0;
  }
  @include media_query(large) {
    padding-right: 0;
  }
  @include media_query(medium) {
    margin: 0 auto;
    padding-inline: 0;
    padding-top: 10rem;
    h3 {
      font-size: $fs32;
      line-height: normal;
    }
    .copyright {
      position: unset !important;
      margin-top: 6.6rem !important;
      margin-bottom: 1.9rem;
      padding: 0 !important;
    }
  }
  @extend .text_center;
  h3 {
    @extend .montserrat_semibold;
  }
  p {
    @extend .montserrat_semibold;
    padding-top: 1.5rem;
  }
  .thankyou_circle {
    margin: 6rem 0;
    img {
      margin: auto;
      @include media_query(medium) {
        width: 10.2rem;
        height: 10.2rem;
      }
    }
  }
  .btn_outline {
    margin: auto;
    max-width: 28.8rem;
    @extend .text_center;
    @extend .justify_content_center;
    padding: 1.6rem 2.4rem;
    &:hover {
      color: $white-color;
    }
  }
}