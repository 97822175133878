.common_header{
    .right {
        .profile_info {
            appearance: none;
            .user_name{
                @include media_query(medium){
                    white-space: nowrap;
                }
            }
        }
    }
}
.header_fixed_box{
    position: absolute;
    padding: 0;
    width: 34rem;
    right: -9.5rem;
    top: 2rem;
    @include media_query(md_small){
        width: 25rem;
        right: -4rem;
        top: 0.4rem;
    }
}
.header_main_box{
    padding: 0;
    appearance: none;
    transition: all 0.5s ease-in-out;
    .header_menu_list{
        .sub_account{
            gap: 1.2rem;
            padding: 1.6rem 1.4rem;
            @extend .cursor_pointer;
            &:hover{
                background-color: $form-input-active-bg;
            }
            border-bottom: 0.1rem solid $form-input-border;
            .u_name{
                h6{
                    color: $primary-color;
                    font-size: $fs16;
                    @extend .montserrat_bold;
                }
            }
        }
    }
}