.confirm-account {
  background-image: url("../images/email_bg.png");
  @extend .w_100;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
  @extend .position_relative;
  @include media_query(large) {
    background-image: none;
    height: 100vh;
    z-index: 0;
    &::after {
      position: absolute;
      content: "";
      background-image: url("../images/email_mobilebg.png");
      width: 100%;
      height: 20rem;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
    }
  }
}
.account-wrapper {
  max-width: 60.9rem;
  margin: 0 auto;
  @extend .pt_100;
  .para_box {
    font-size: $fs16;
    @extend .montserrat_medium;
    color: $gray-two;
    line-height: 1.9rem;
    @include media_query(small) {
      font-size: $fs14;
      line-height: 1.7rem;
    }
  }
  .button-box {
    @extend .mt_50;
    @extend .pb_100;
    @extend .mb_50;
    @include media_query(large) {
      margin-bottom: 1rem;
      padding-bottom: 2rem;
      margin-top: 2rem;
    }
    p {
      @extend .montserrat_semibold;
      font-size: $fs16;
      line-height: 1.9rem;
      color: $gray-three;
      margin-bottom: 1rem;
    }
    .btn_primary {
      max-width: 36.2rem;
      background-color: $primary-color;
      margin: auto;
      padding: 1.55rem;
      @extend .d_flex;
      &:hover {
        color: $white-color;
      }
    }
  }
  .footer_text {
    font-size: $fs16;
    @extend .montserrat_medium;
    color: $gray-three;
    line-height: 1.9rem;
    @extend .pb_100;
    @include media_query(large) {
      padding-bottom: 2rem;
    }
    a {
      color: $secondary-color;
      @extend .montserrat_bold;
      @include trans;
      &:hover {
        border-bottom: 0.1rem solid;
        @include trans;
      }
    }
    position:static;
    padding-left: 0;
  }
  @include media_query(large) {
    padding-top: 0;
  }
  @include media_query(medium) {
    max-width: 46.2rem;
  }
  @include media_query(small) {
    padding-top: 7rem;
    max-width: 36.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.custom-heading {
  @include media_query(large) {
    margin-bottom: 2rem;
  }
  @include media_query(small) {
    margin-bottom: 5rem;
  }
  h1 {
    font-size: $fs40;
    color: $black-color;
    @extend .montserrat_semibold;
    line-height: 4.8rem;
    @extend .mt_50;
    @extend .mb_20;
    @include media_query(large) {
      margin-top: 2rem;
    }
    @include media_query(small) {
      padding-top: 5rem;
      font-size: $fs36;
      line-height: 3.6rem;
      margin-bottom: 1rem;
    }
  }
  .sub_head {
    @extend .montserrat_semibold;
    color: $gray-three;
    font-size: $fs14;
    line-height: 1.7rem;
    @include media_query(small) {
      font-size: $fs12;
      line-height: 1.4rem;
    }
  }
  @extend .mb_50;
}