* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
html {
  font-size: 10px;
  scrollbar-width: thin;
  scrollbar-color: $scroll-color $form-input-active-bg;
}

body {
  @extend .montserrat_regular;
}
h1 {
  @extend .montserrat_bold;
  font-size: $fs56;
  line-height: 61.6px;
  @include space;
}
h2 {
  @extend .montserrat_bold;
  font-size: $fs48;
  line-height: 52.8px;
  @include space;
}
h3 {
  @extend .montserrat_bold;
  font-size: $fs40;
  line-height: 44px;
  @include space;
}
h4 {
  @extend .montserrat_bold;
  font-size: $fs32;
  list-style: 35.2px;
  @include space;
}
h5 {
  @extend .montserrat_bold;
  font-size: $fs24;
  line-height: 26.4px;
  @include space;
}
h6 {
  @extend .montserrat_bold;
  font-size: $fs20;
  line-height: 22px;
  @include space;
}
button {
  background-color: transparent;
  border: none;
}

input[type="password"] {
  font-family: "fontello" !important;
  font-size: 1.2rem !important;
}
// popup scroll
.no-scroll {
  overflow-y: hidden !important;
  @extend .position_fixed;
  @extend .w_100;
}
// // login form css
.login_form {
  background-position: 100%;
  background-size: contain;
  @extend .position_relative;
  @extend .w_100;
  min-height: 100vh;
  &.sign_in_form {
    .forgot_pass {
      @extend .d_none;
    }
  }
  .container {
    max-width: 144rem;
    @include media_query(one_extra_large) {
      padding-inline: 0;
    }
    &:after {
      content: "";
      @extend .position_absolute;
      background: url(../images/mentor_home.png);
      width: 50%;
      @extend .h_100;
      top: 0;
      right: 0;
      background-size: cover;
      z-index: -1;
      @include media_query(medium) {
        display: none;
        content: none;
      }
    }
    .mobile_logo {
      @extend .d_none;
      @include media_query(medium) {
        display: block;
        text-align: center;
        padding-top: 2.5rem;
        img {
          margin: 0 auto;
        }
      }
    }
    .login_info {
      @extend .d_flex;
      @extend .justify_content_center;
      @extend .align_items_center;
      @include media_query(one_extra_large) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      @include media_query(medium) {
        grid-template-columns: 1fr;
      }
      @include media_query(medium) {
        padding-top: 2.4rem;
      }
      .login_details {
        @extend .montserrat_semibold;
        background: $white-color;
        max-width: 50%;
        @extend .w_100;
        @include media_query(one_extra_large) {
          max-width: 100%;
          padding-inline: 3.4rem;
        }
        @include media_query(medium) {
          max-width: 100%;
        }
        .mentor_regi {
          @extend .w_100;
          max-width: 41.4rem;
          @extend .m_auto;
        }
        form {
          max-width: 36.2rem;
          @extend .w_100;
          @extend .m_auto;
          @include media_query(small) {
            max-width: 100%;
          }
          .form_group {
            input[type="password"]::placeholder {
              font-family: "fontello" !important;
              font-size: 1.6rem !important;
            }
            input[type="text"]::placeholder {
              font-family: "Montserrat_regular";
              font-size: 1.6rem !important;
            }
            &.mb-0 {
              margin-bottom: 1rem;
            }
            &.disable_filed {
              label {
                color: $gray-four;
              }
              input {
                &.false {
                  border: 0.2rem solid $gray-four;
                  background-color: transparent;
                }
              }
            }
            .btn_primary,
            .btn_secondary {
              max-width: 36.2rem;
              min-height: 5.5rem;
              &:hover {
                background-color: $primary-color;
                &:after{
                  @extend .d_none;
                }
              }
              @include media_query(small) {
                padding: 1.6rem;
                max-width: 100%;
              }
            }
            label {
              margin-bottom: 0.8rem;
            }
            .account_yet {
              font-size: $fs16;
              color: $gray-three;
              @extend .text_center;
              margin-bottom: 13.3rem;
              @include media_query(small) {
                margin-bottom: 0rem;
              }
              @extend .montserrat_medium;
              .sign_up_btn {
                color: $btn_primary;
                text-decoration: none;
                @extend .montserrat_bold;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            .forgot_pass {
              @extend .montserrat_semibold;
              @extend .cursor_pointer;
              font-size: $fs14;
              color: $gray-three;
              margin-top: 0.5rem;
            }

            .form_control {
              @extend .montserrat_regular;
              font-size: $fs16;
              padding: 0 1.6rem;
              min-height: 5.5rem;
              &[type="password"],
              &[type="text"] {
                padding-right: 5rem;
              }
              &input[type="text"]::placeholder {
                font-family: "Montserrat_regular";
              }
              &.border_green {
                border: 0.2rem solid $form_bdr;
              }
              &.border_red {
                border: 0.2rem solid $red-required;
              }
              &:focus {
                outline: none;
              }
              &:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 40px white inset !important;
              }
              &.pd_top_pass {
                padding-top: 0.6rem;
              }
            }
            .form_p {
              color: $light-gray;
            }
            .error_txt {
              color: $gray-three;
              font-size: $fs14;
              @extend .montserrat_semibold;
            }
          }
          .eye_pass {
            @extend .position_absolute;
            @extend .cursor_pointer;
            right: 1.6rem;
            top: 5.5rem;
            transform: translateY(-40%);
          }
        }
        .copyright {
          color: $gray-three;
          font-size: $fs14;
          margin-top: 19.2rem;
          @extend .text_center;
          @extend .montserrat_semibold;
          &.text_left {
            text-align: left;
            @extend .montserrat_semibold;
          }
          @include media_query(medium) {
            position: absolute;
            left: 0;
            right: 0;
            padding-inline: 3.4rem;
            padding-top: 4rem;
            padding-bottom: 4rem;
            margin-top: 0;
          }
        }
        .condition_policy {
          color: $btn_primary;
          text-decoration: none;
          @extend .montserrat_semibold;
          &:hover {
            text-decoration: underline;
          }
        }
        .login_txt {
          color: $black-color;
          font-size: $fs40;
          @extend .montserrat_semibold;
          @include media_query(medium) {
            text-align: left;
          }
        }
        .login_desc {
          color: $gray-three;
          font-size: $fs14;
          margin-top: 1.5rem;
          margin-bottom: 3.2rem;
          @extend .montserrat_semibold;
          @include media_query(medium) {
            text-align: left;
          }
        }
      }
      .login_info_img {
        padding-left: 6.4rem;
        padding-top: 8rem;
        @include media_query(medium) {
          display: none;
        }
        .champion_title {
          font-size: $fs40;
          @extend .montserrat_medium;
          color: $black-color;
          padding: 3.2rem 0;
          padding-right: 2rem;
        }
        .champion_desc {
          font-size: $fs16;
          color: $gray-two;
          @extend .montserrat_medium;
          margin-bottom: 6.4rem;
          margin-right: 9rem;
        }
        span {
          img {
            @extend .w_100;
          }
        }
      }
    }
  }

  &.forgot_pass_page {
    .go_back {
      max-width: 100% !important;
      border: transparent !important;
      line-height: 22.4px;
      margin: 2rem 0;
      @extend .d_flex_center;
      @extend .fs_16;
      &:hover {
        color: $white-color;
      }
      & a {
        color: $primary-color;
      }
    }
    .error_msg {
      @extend .text_right;
      color: $red-required;
      font-size: $fs14;
    }
    .forgot_model_popup {
      background: rgba(22, 35, 83, 0.5);
      @extend .position_absolute;
      @extend .w_100;
      @extend .h_100;
      top: 0;
      .model_popup_detail {
        @extend .w_100;
        max-width: 47.5rem;
        background-color: $white-color;
        box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
        margin: auto;
        @extend .text_center;
        @extend .position_absolute;
        top: 40%;
        left: 0;
        right: 0;
        padding: 3.2rem;
        border-radius: 2rem;
        @include media_query(md_small) {
          max-width: 37rem;
        }
        & .pass_close_btn img {
          right: 1rem;
          @extend .position_absolute;
          top: 1rem;
          transform: scale(1.5);
          @extend .cursor_pointer;
        }
        h6 {
          margin-bottom: 2.4rem;
          color: $black-color;
          font-size: $fs18;
          @extend .montserrat_bold;
        }
        button {
          top: 2rem;
        }
        p {
          margin-top: 2.4rem;
          color: $black-color;
        }
      }
    }
  }
  &.change_pass {
    .error_list {
      p {
        color: $form-lable-color;
        @extend .d_block;
        font-size: $fs14;
        @extend .montserrat_bold;
      }
      ul {
        margin-bottom: 3.2rem;
        margin-left: 0;
        list-style: unset;
        padding-left: 1.5rem;
        li {
          color: $form-lable-color;
          font-size: $fs14;
          @extend .montserrat_regular;
          padding: 0.3rem 0;
        }
      }
    }
  }
  &.mentor_inquiry_form {
    .container {
      padding-inline: 3.5rem;
      @include media_query(three_extra_large) {
        padding-inline: 2.5rem;
      }
      @include media_query(one_extra_large) {
        padding-inline: 0rem;
      }
      .login_info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include media_query(one_extra_large) {
          align-items: flex-start;
        }
        @include media_query(medium) {
          grid-template-columns: 1fr;
        }
        .login_details {
          @extend .w_100;
          max-width: 100%;
          padding-inline: 3.4rem;
          @include media_query(one_extra_large) {
            padding-inline: 3.4rem;
          }
          h2 {
            @include media_query(large) {
              font-size: 3rem;
            }
          }
          form {
            max-width: 64.8rem;
            padding-top: 4rem;
            @include media_query(medium) {
              padding-top: 0;
            }
            h2 {
              @include media_query(small) {
                text-align: center;
              }
            }
            .login_desc {
              margin-bottom: 0;
              @include media_query(small) {
                text-align: center;
              }
            }
            .hr {
              border-color: $separtor-color;
              margin: 4rem 0;
            }
            .inquiry_form_wrapper {
              .form_box {
                padding-inline: 3rem;
                @include media_query(three_extra_large) {
                  padding-inline: 2rem;
                }
                @include media_query(two_extra_large) {
                  padding-inline: 1rem;
                }
                h4 {
                  margin-top: 3.2rem;
                  font-size: $fs20;
                  margin-bottom: 3.2rem;
                }
                .form_layouts {
                  display: grid;
                  column-gap: 2.4rem;
                  grid-template-columns: 1fr 1fr;
                  @include media_query(large) {
                    grid-template-columns: 1fr;
                  }
                }
                .form_group {
                  .geosuggest {
                    @extend .position_relative;
                  }
                  .geosuggest__suggests-wrapper {
                    @extend .position_absolute;
                    @extend .w_100;
                    z-index: 9;
                    .all_location {
                      background: $white-color;
                      @extend .border_0;
                      border-radius: 0.4rem;
                      box-shadow: 0 0 30px 0 hsla(0, 0%, 45%, 0.15);
                      max-height: 23.5rem;
                      overflow: auto;
                      @extend .w_100;
                      .geosuggest__item {
                        font-size: $fs16;
                        @extend .montserrat_medium;
                        color: $gray-color;
                        line-height: 140%;
                        min-height: 5.5rem;
                        padding: 0.8rem;
                        @extend .d_flex_align;
                        &:has(.activeCity){
                          background-color: $table-hover;
                          @extend .montserrat_bold;
                        }
                        &:hover {
                          background-color: $table-hover;
                          @extend .montserrat_bold;
                        }
                      }
                    }
                  }
                  label {
                    color: $gray-color;
                  }
                  .form_control {
                    border-color: $separtor-color;
                    min-height: 4.8rem;
                    &::placeholder {
                      color: $black-two;
                      opacity: 0.5;
                    }
                    &#gender {
                      appearance: none;
                      background: url("../images/select_arrow.svg");
                      background-repeat: no-repeat;
                      background-position: right 1.5rem center;
                      padding-right: 5rem;
                      line-height: 4.5rem;
                      @extend .cursor_pointer;
                    }
                  }
                }
              }
              .btn_group {
                gap: 0.8rem;
                @extend .d_flex;
                margin-bottom: 4rem;
                @extend .justify_content_center;
                @include media_query(small) {
                  flex-direction: column;
                }
                button {
                  padding: 1.6rem 2rem;
                  max-width: 28.8rem;
                  border-color: $primary-color;
                  max-height: 5.6rem;
                  &:hover {
                    background-color: $primary-color;
                    color: $white-color;
                  }
                  &.btn_primary {
                    background-color: $primary-color;
                  }
                  @include media_query(small) {
                    max-width: 100%;
                  }
                }
              }
            }
          }
          .copyright {
            margin-top: 2.4rem;
            position: unset;
            padding-bottom: 4rem;
          }
          .terms {
            @extend .text_center;
            @extend .montserrat_semibold;
            a {
              color: $secondary-color;
            }
          }
        }
      }
    }
  }
  &.change_pass {
    .container {
      .login_info {
        .login_details {
          .login_txt {
            font-size: 3.9rem;
            @include media_query(large) {
              padding-top: 2.5rem;
            }
            @include media_query(medium) {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}
.forgot_popup_height {
  height: 24rem !important;
}
.password_popup_height {
  height: 32.1rem !important;
}
.alert_popup_height {
  height: 30rem !important;
}
.forgot_model_popup {
  @extend .w_100;
  @extend .h_100;
  .model_popup_detail {
    @extend .w_100;
    max-width: 47.5rem;
    box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
    margin: auto;
    @extend .text_center;
    top: 40%;
    left: 0;
    right: 0;
    padding: 3.2rem;
    border-radius: 2rem;
    & .pass_close_btn img {
      right: 1rem;
      @extend .position_absolute;
      top: 1rem;
      transform: scale(1.5);
      @extend .cursor_pointer;
    }
    h6 {
      margin-bottom: 2.5rem;
      color: $black-color;
      font-size: $fs18;
      @extend .montserrat_bold;
    }
    p {
      margin-top: 2.4rem;
      color: $black-color;
    }
  }
}
.change_pass_model_popup {
  height: 321px;
  .btn_primary {
    max-width: 20.4rem;
    padding: 1.2rem 2.4rem;
    margin-top: 3.2rem;
  }
}
.rc-time-picker-panel-combobox {
  .rc-time-picker-panel-select {
    ul {
      li {
        &.rc-time-picker-panel-select-option-selected {
          background-color: $btn_primary;
          color: $white-color;
        }
        &:hover {
          background-color: $blue-color;
          color: $white-color;
        }
      }
    }
  }
}
.react-datepicker__day {
  @extend .d_inline_block;
  @extend .position_relative;
}
.event-dot {
  @extend .position_absolute;
  width: 0.8rem;
  height: 0.8rem;
  background-color: #3788d8;
  border-radius: 50%;
  top: -1.2rem;
  left: 0.8rem;
}
.custom-header {
  @extend .d_flex_between;
}
.cookies_popup_wrapper {
  @extend .position_fixed;
  bottom: 2.6rem;
  left: 0;
  right: 0;
  @extend .d_flex;
  @extend .justify_content_center;
  z-index: 999;
  margin: 0 1.5rem;
  animation: cookiesPopup 0.8s ease-in-out;

  .ck_popup_box {
    @extend .w_100;
    max-width: 57.6rem;
    border-radius: 0.8rem;
    border: 0.1rem solid $btn_disable;
    background-color: $white-color;
    box-shadow: 0px 8px 141.11px 0px rgba(30, 45, 102, 0.28);
    padding: 2.4rem 3.2rem;
    @extend .position_relative;
    @include media_query(small) {
      max-width: 45rem;
    }
    .ck_img {
      width: 8rem;
      height: 8rem;
      background-color: $form-input-active-bg;
      @extend .d_flex_center;
      border-radius: 5rem;
      margin-top: 2rem;
      @include media_query(small) {
        height: 6rem;
      }
    }
    .ck_detail {
      width: 100%;
      max-width: 40.8rem;
      margin-left: 2.4rem;
      .ck_popup_close {
        @extend .position_absolute;
        @extend .cursor_pointer;
        right: 3.2rem;
      }
      h5 {
        padding-bottom: 1.2rem;
      }
      .ck_desc {
        color: $black-color;
        font-size: $fs16;
        a {
          text-decoration: underline;
          @extend .montserrat_medium;
        }
      }
    }
    .accept_ck_btn {
      @extend .text_center;
      margin-top: 2.4rem;
      .btn_primary {
        max-width: 24rem;
        min-height: 5.4rem;
        @extend .d_inline_block;
        line-height: 3.8rem;
        &:hover {
          color: $white-color;
        }
      }
    }
  }
}
@keyframes cookiesPopup {
  0% {
    opacity: 0;
    bottom: -26rem;
  }
  100% {
    opacity: 1;
    bottom: 2.6rem;
  }
}
