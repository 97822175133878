@import "variable";
@import "fonts";
@import "mixin";
@import "button";
@import "classes";
@import "block";
@import "confirm_email";
@import "pre_match";
@import "sidebar";
@import "schedule_orientation";
@import "popup";
@import "mentor_home";
@import "style";
@import "table";
@import "modal";
@import "mentees_profile";
@import "basic_info";
@import "messages";
@import "signsuccess";
@import "events";
@import "progress";
@import "home_full";
@import "searchbar";
@import "content";
@import "general";
@import "no_data";
@import "thank_you";
@import "header_menu";
input[type="search"]:disabled::-webkit-search-cancel-button,
input[type="search"]:disabled::-webkit-search-decoration,
input[type="search"]:disabled::-webkit-search-results-button,
input[type="search"]:disabled::-webkit-search-results-decoration,
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
