.progress_wrapper {
  padding: 3.2rem;
  @include media_query(small) {
    padding: 2rem 0 10rem;
    padding-top: 5.5rem;
  }
  h2 {
    font-size: $fs24;
    @extend .montserrat_medium;
    color: $black-color;
    line-height: normal;
    margin-bottom: 1.5rem;
    @include media_query(medium) {
      font-size: $fs20;
    }
    @include media_query(small) {
      margin-top: 1.5rem
    }
    span {
      @extend .montserrat_bold;
    }
  }
  .progress_box {
    @include media_query(small) {
      padding: 2.5rem;
      background-color: $login_bg;
    }
    @extend .position_relative;
    .progress_btn {
      @extend .position_absolute;
      right: 2.9rem;
      top: 0;
      @include media_query(two_extra_large) {
        right: 0;
      }
      @include media_query(small) {
        top: 2.5rem;
        right: 2.5rem;
      }
      .slider_btn {
        img {
          @extend .d_block;
          @include trans;
          &:hover {
            filter: brightness(0.5);
          }
        }
        &.next_btn {
          transform: rotate(180deg);
          padding-right: 1rem;
        }
        .disable {
          background-color: $grey-text;
          -webkit-user-select: none;
          user-select: none;
          cursor: not-allowed;
        }
      }
    }
    .preogrss_slider {
      margin-right: 0rem;
      .slick-slider {
        .slick-list {
          @include media_query(two_extra_large) {
            overflow: visible;
          }
          .slick-track {
            margin: 0 -1.6rem;
            .slick-slide {
              padding: 0 1.6rem;
            }
            @include media_query(small) {
              margin: 0 -1.3rem;
              .slick-slide {
                padding: 0 1.3rem;
              }
            }
          }
        }
        @include media_query(medium) {
          padding-right: 6rem;
        }
        @include media_query(small) {
          padding-right: 9rem;
        }
        @include media_query(extra_small) {
          padding-right: 2rem;
        }
      }
      .slick-slider {
        .slick-arrow {
          height: 2.4rem;
          width: 2.4rem;
          top: -3rem;
          @extend .d_flex_center;
          @extend .radius-50;
          background-color: $btn_primary;
          &.slick-prev {
            left: auto;
            right: 0rem;
            margin-right: 7rem;
            @include media_query(two_extra_large) {
              margin-right: 4rem;
            }
            &::before {
              opacity: 1;
              content: "";
              width: 1rem;
              height: 1rem;
              border: 0.2rem solid $white-color;
              transform: rotate(45deg);
              margin-left: 0.3rem;
              border-right: 0;
              border-top: 0;
            }
            &.slick-disabled {
              background-color: $grey-text;
            }
          }
          &.slick-next {
            right: 0rem;
            left: initial;
            margin-right: 3rem;
            @include media_query(two_extra_large) {
              margin-right: 0rem;
            }
            &::before {
              opacity: 1;
              content: "";
              width: 1rem;
              height: 1rem;
              border: 0.2rem solid $white-color;
              transform: rotate(45deg);
              border-bottom-color: transparent;
              border-left: 0;
              margin-left: -0.2rem;
            }
            &.slick-disabled {
              background-color: $grey-text;
            }
          }
        }
      }
      .progress_list {
        padding: 2.6rem 2.8rem 7rem;
        border: 0.1rem solid $btn_disable;
        border-radius: 0.9rem;
        box-shadow: 0px 4px 6px 0px rgba(36, 73, 137, 0.08);
        -webkit-box-shadow: 0px 4px 6px 0px rgba(36, 73, 137, 0.08);
        background-color: $white-color;
        width: 36.2rem;
        min-height: 36.5rem;
        @extend .position_relative;
        @include media_query(two_extra_large) {
          padding: 2.6rem 2rem 7rem;
          width: 33.5rem;
        }
        @include media_query(small) {
          min-height: 30rem;
        }
        strong {
          font-size: $fs21;
          color: $black-color;
          @extend .montserrat_semibold;
          @include media_query(two_extra_large) {
            font-size: $fs18;
          }
          @include media_query(large) {
            font-size: $fs16;
          }
        }
        .progress_profile {
          img {
            @extend .custom_box_shadow;
            @extend .radius-50;
            height: 4.8rem;
            width: 4.8rem;
            margin-right: 1.2rem;
            object-fit: cover;
          }
          margin-bottom: 2rem;
        }
        .progress_data {
          strong {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          span {
            @extend .d_block;
            @extend .montserrat_regular;
            font-size: 2.2rem;
            color: $black-color;
          }
          .profile_stat {
            margin-top: 1.1rem;
            font-size: 1.7rem;
            @extend .montserrat_bold;
            @extend .text_capitalize;
            &.pending_text {
              color: $saffron_yellow;
            }
            &.completed_text {
              color: $form_bdr;
            }
            &.inprogress_text {
              color: $primary-color;
            }
          }
        }
        .custom_progressbar {
          @extend .position_absolute;
          @extend .w_100;
          left: 0;
          right: 0;
          padding: 0 2.8rem;
          bottom: 2.4rem;
        }
      }
    }
    &.progress_detail {
      background-color: $white-color;
      .preogrss_slider {
        gap: 3.2rem;
        @extend .d_flex;
        flex-wrap: wrap;
        @include media_query(large) {
          grid-template-columns: 1fr 1fr;
          display: grid;
        }
        @include media_query(medium) {
          grid-template-columns: 1fr;
        }
      }
      h2{
        @include media_query(small){
          margin-top: 7.5rem;
        }
      }
      .progress_list {
        padding: 3.2rem 2.4rem 7rem;
        min-height: 24.4rem;
        .progress_data {
          margin-bottom: 2.8rem;
          strong {
            min-height: 6.3rem;
          }
        }
        @include media_query(large) {
          width: 100%;
        }
      }
    }
  }
  .progress_connection {
    padding-top: 3.1rem;
    @include media_query(small) {
      padding-inline: 2.5rem;
    }
    .table_wrapper {
      @extend .p_0;
      background-color: $white-color;
      .table_responsive {
        .progress {
          border-collapse: collapse;
          @include media_query(medium) {
            width: 100%;
            display: block;
            min-height: 32.7rem;
          }
          tr {
            @include media_query(medium) {
              width: 100%;
              display: block;
              @include media_query(medium) {
                border: 0;
                &:hover {
                  background-color: $white-color;
                }
              }
            }
            td {
              @extend .border_0;
              @include media_query(large) {
                display: table-cell;
              }
              &:last-child {
                width: auto;
                padding-right: 1.2rem;
                @include media_query(medium) {
                  display: none;
                }
              }
              // &:nth-child(4) {
              //   width: 19rem;
              //   @include media_query(large) {
              //     padding-left: 0rem;
              //     width: 17rem;
              //     &.badge_img {
              //       padding-left: 1rem;
              //     }
              //   }
              //   @include media_query(medium) {
              //     position: absolute;
              //     top: 21rem;
              //     width: 100%;
              //     p.thead {
              //       font-size: $fs24;
              //       font-family: "Montserrat_semibold";
              //       font-weight: 600;
              //       color: $gray-two;
              //     }
              //   }
              // }
              @include media_query(medium) {
                &:nth-child(2),
                &:nth-child(3) {
                  display: none;
                }
              }
              &.badge {
                @extend .d_none;
                font-size: $fs24;
                @extend .montserrat_semibold;
                color: $tab-text;
                @include media_query(medium) {
                  display: block;
                  margin-top: -2.5rem;
                  padding-left: 0;
                }
              }
              .dot_icon_modal {
                @include media_query(large) {
                  right: 1.2rem;
                }
              }
            }
          }
          thead {
            @include media_query(medium) {
              display: block;
              width: 100%;
              tr {
                td {
                  &:nth-child(4) {
                    display: none;
                  }
                }
              }
            }
          }
          tbody {
            tr {
              border-top: 0.1rem solid $btn_disable;
              @include media_query(medium) {
                border-top: 0;
                border: 0.1rem solid $btn-disable;
                padding: 3.2rem 2rem;
                border-radius: 0.4rem;
                margin-bottom: 3rem;
                box-shadow: 0px 4px 12px 0px rgba(36, 73, 137, 0.08);
                min-height: 32.7rem;
                position: relative;
              }
              td {
                @include media_query(medium) {
                  &:nth-child(2) {
                    display: block;
                    width: 100%;
                  }
                }
                @include media_query(medium) {
                  &:nth-child(4) {
                    top: 25rem;
                    padding-inline: 2rem;
                    left: 0;
                    right: 0;
                  }
                }
              }
            }
            @include media_query(medium) {
              display: block;
              width: 100%;
              padding-top: 0.2rem;
            }
          }
          .mentor_views {
            .d_flex_align {
              @include media_query(medium) {
                flex-direction: column;
              }
            }
            .name_toolkit {
              flex-direction: column;
            }
            .mobile_type {
              @extend .d_none;
              @extend .text_uppercase;
              @extend .montserrat_bold;
              font-size: 1rem;
              color: $primary-color;
              padding: 0.5rem 1.1rem;
              background-color: $fade-blue;
              @extend .radius-50;
              margin-top: -1.8rem;
              border-top: 0.3rem solid $white-color;
              img {
                margin-right: 0.7rem;
              }
              @include media_query(medium) {
                display: flex;
                justify-content: center;
              }
            }
            .user_pic {
              @include media_query(medium) {
                width: 11rem;
                margin-right: 0;
              }
              img {
                width: 2.4rem;
                height: 2.4rem;
                @extend .m_auto;
                @extend .radius-50;
                object-fit: cover;
                @include media_query(medium) {
                  width: 11rem;
                  height: 11rem;
                  object-fit: cover;
                }
              }
            }
            .user_name {
              margin-left: 0.7rem;
              @include media_query(medium) {
                font-family: "Montserrat_bold";
                font-weight: 700;
                margin-top: 1.1rem;
                display: block;
                color: $black-color;
                margin-left: 0;
              }
            }
          }
          .badge_img {
            .name_toolkit {
              .user_name {
                max-width: 100% !important;
                @extend .justify_content_between;
                @extend .w_100;
                @extend .d_flex;
                @include media_query(large) {
                  justify-content: space-between;
                }
                @include media_query(medium) {
                  justify-content: center;
                }
                span {
                  @extend .d_flex;
                  img {
                    width: 2.4rem;
                    height: 2.4rem;
                    margin: 0 0.5rem;
                  }
                }
                .link_btn {
                  color: $btn_primary;
                  @extend .montserrat_medium;
                  font-size: $fs16;
                  line-height: 140%;
                  border-bottom: 0;
                  margin-left: auto;
                  width: 6.4rem;
                  @include media_query(large) {
                    margin-left: 1rem;
                    font-size: $fs14;
                    width: auto;
                  }
                  &:hover {
                    color: $primary-color;
                    @extend .montserrat_semibold;
                  }
                  @include media_query(medium) {
                    // position: absolute;
                    // right: 0;
                    // top: -3.8rem;
                  }
                }
                &:hover {
                  border-bottom: 0 !important;
                }
              }
            }
          }
        }
      }
    }
    .pagination {
      @extend .p_0;
      @include media_query(small) {
        display: none;
      }
    }
  }
  .modal_popup.personalised {
    @include media_query(small) {
      // min-height: 65.1rem;
      min-height: 25rem;
    }
  }
}
.custom_progressbar {
  .bar_info {
    * {
      font-size: 1.7rem;
      color: $black-color;
    }
    p {
      @extend .montserrat_semibold;
    }
    span {
      @extend .montserrat_regular;
    }
    margin-bottom: 1.1rem;
  }
  .progress_design {
    @extend .w_100;
    height: 1.2rem;
    background-color: $progress_bar;
    border-radius: 1.21rem;
    @extend .position_relative;
    .progress_fill {
      @extend .position_absolute;
      width: 0%;
      height: 1.2rem;
      left: 0;
      top: 0;
      border-radius: inherit;
      background-color: $primary-color;
    }
  }
}