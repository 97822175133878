.nodatabox{
    @extend .position_absolute;
    right: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    .nodatainfo{
        @extend .m_auto;
        width: 30.4rem;
        display: grid;
        @extend .justify_content_center;
        @extend .text_center;
        .data_img{
            width: 12rem;
            height:12rem;
            border-radius:10rem;
            background-color: $form-input-active-bg;
            display: grid;
            place-items: center;
            @extend .m_auto;
        }
        h2{
            font-size:$fs24;
            @extend .montserrat_semibold;
            color:$tab-head;
            line-height: normal;
            margin: 2.4rem 0 1.6rem;
        }
        p{
            font-size:$fs16;
            @extend .montserrat_regular;
            color:$light-gray;
            line-height: 140%;
        }
    }
}