.modal_comman {
  @extend .w_100;
  padding: 0 1.5rem;
  @extend .position_relative;
  z-index: 99;
  animation: popupRoll .6s ease-in-out forwards;
  @extend .d_none;
  &::after {
    content: "";
    @extend .position_fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    background-color: #1623534d;
    transition: all 0.5s ease-in-out;
    z-index: 0;
  }
  .delete_modal {
    @extend .d_none;
    @extend .w_100;
    max-width: 47.5rem;
    padding: 3.2rem;
    @extend .text_center;
    border-radius: 2rem;
    background: var(--black-colors-white, $white-color);
    box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
    @extend .position_fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    &.active {
      @extend .d_block;
    }
    .close_btn {
      width: 4rem;
      height: 4rem;
      @extend .position_absolute;;
      top: 1.5rem;
      right: 2rem;
      @extend .cursor_pointer;
    }
    h2 {
      @extend .fs_18;
      @extend .montserrat_bold;
      @extend .m_auto;
      @extend .text_center;
      max-width: 30.2rem;
      color: $black-color;
      line-height: 140%;
      margin-bottom: 2.5rem;
      @include media_query(small) {
        max-width: 28.2rem;
        @include media_query(extra_small) {
          margin-top: 2.2rem;
        }
      }
    }
    .user_pic {
      width: 6.55rem;
      height: 6.55rem;
      @extend .d_block;
      @extend .m_auto;
      margin-bottom: 0.65rem;
      img {
        @extend .radius_round;
        @extend .w_100;
        @extend .img_object_center;
      }
    }
    .user_name {
      @extend .d_block;
      color: $black-color;
      @extend .text_center;
      @extend .fs_14;
      @extend .montserrat_bold;
      line-height: 140%;
      margin-bottom: 2.4rem;
    }
    .delete_msg {
      color: $black-color;
      @extend .text_center;
      @extend .fs_14;
      line-height: 140%;
      margin-bottom: 3.2rem;
    }
    .delete_btn_group {
      gap: 3.2rem;
      @include media_query(small) {
        gap: 1rem;
        .btn {
          font-size: $fs14;
          min-width: 15rem;
          padding: 1rem;
        }
      }
      @include media_query(extra_small) {
        flex-direction: column;
      }
    }
    @include media_query(small) {
      max-width: 90%;
      padding: 2rem;
    }
  }
}
.modal_popup {
  background-color: $white-color;
  border-radius: 1.6rem;
  border: 1px solid $btn_disable;
  padding: 3.2rem;
  max-width: 74.6rem;
  @extend .w_100;
  @extend .m_auto;
  @include media_query(two_extra_large) {
    max-width: 65.6rem;
  }
  @include media_query(large) {
    max-width: 60rem;
  }
  @include media_query(small) {
    margin-bottom: 16.8rem;
    max-width: 100%;
    border: 0;
  }
  @include media_query(extra_small) {
    padding: 2rem;
  }

  .modal_heading {
    margin-bottom: 3.2rem;
    @include media_query(small) {
      justify-content: center;
      padding-top: 2rem;
      &.share_head {
        padding-top: 0rem;
        @include media_query(extra_small){
          padding-top: 1.2rem;
        }
      }
      .modal_popup_close {
        position: absolute;
        right: 3.2rem;
        top: 2rem;
        @include media_query(extra_small) {
          right: 1rem;
          top: 1rem;
        }
        &.back_to_btns{
          @include media_query(small) {
            left: 2rem;
            top: 2rem;
            right: unset;
          }
        }
      }
    }
    h2 {
      margin-bottom: 0;
      font-size: $fs20;
      @extend .montserrat_bold;
    }
    .modal_popup_close {
      img {
        @extend .cursor_pointer;
      }
    }
  }
  .badge_match_box {
    @include media_query(small) {
      padding-block: 3.5rem;
    }
    h3 {
      @extend .d_none;
    }
    background-color: $profile-bg;
    padding-block: 2rem;
    margin-bottom: 2rem;
    @extend .position_relative;
    border-radius: 0.8rem;
    z-index: 0;
    &::after,
    &::before {
      @extend .position_absolute;
      content: "";
      width: 10.7rem;
      height: 10.7rem;
      background-image: url("../images/Badge_union.svg");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      left: 0;
      z-index: -1;
    }
    &::after {
      background-image: url("../images/Badge_union_bg.svg");
      left: auto;
      right: 0;
      bottom: 0.5rem;
      width: 12.9rem;
      height: 12.9rem;
    }
    .matches_users {
      width: 17.6rem;
      @include media_query(small) {
        width: 15.6rem;
      }
      @include media_query(extra_small) {
        width: 13.6rem;
      }
      h6 {
        @extend .montserrat_bold;
        font-size: $fs14;
        color: $black-color;
        @extend .text_capitalize;
        margin-bottom: 1.4rem;
        line-height: 140%;
        @include media_query(small) {
          margin-bottom: 0.8rem;
        }
        @include media_query(extra_small) {
          font-size: $fs12;
        }
      }
      .match_profile_img {
        img {
          @extend .d_block;
          width: 8.7rem;
          height: 8.7rem;
          object-fit: cover;
          @extend .radius-50;
          @extend .m_auto;
          @include media_query(extra_small) {
            width: 7rem;
            height: 7rem;
          }
        }
      }
      .tag_name {
        background-color: $fade-blue;
        @extend .radius-50;
        padding: 0.4rem 1.1rem;
        border: 2px solid $white-color;
        max-width: 9.5rem;
        margin: -0.7rem auto 0;
        @extend .position_relative;
        z-index: 0;
        img {
          width: 1.7rem;
        }
        p {
          @extend .montserrat_bold;
          @extend .text_uppercase;
          font-size: 1rem;
          color: $primary-color;
        }
      }
      &.user_one {
        -webkit-animation: slideInLeft linear;
        animation: slideInLeft linear;
        -webkit-animation-duration: 0.35s;
        animation-duration: 0.35s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        @include trans;
      }
      &.user_two {
        -webkit-animation: slideInRight linear;
        animation: slideInRight linear;
        -webkit-animation-duration: 0.35s;
        animation-duration: 0.35s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        @include trans;
      }
    }
    .s_checkmark {
      @extend .position_relative;
      z-index: 0;
      @include trans;
      img {
        width: 3.2rem;
        height: 3.2rem;
        @include media_query(extra_small) {
          width: 3rem;
          height: 3rem;
        }
      }
      &::after {
        @extend .position_absolute;
        content: "";
        background-image: url("../images/match_border.svg");
        background-repeat: no-repeat;
        width: 8.5rem;
        height: 0.5rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-size: cover;
        z-index: -1;
      }
    }
    &.disable_user_animate {
      h3 {
        @extend .d_inline_block;
        @extend .text_center;
        font-size: $fs14;
      }
      .matches_users {
        &.user_one,
        &.user_two {
          @extend .d_none;
        }
      }
      .s_checkmark {
        &.animate_success {
          img {
            width: 9.4rem;
            height: 9.4rem;
          }
        }
      }
    }
  }
  .badge_listing {
    .badge_box {
      gap: 4rem 1rem;
      flex-wrap: wrap;
      @extend .justify_content_center;
      @include media_query(large) {
        gap: 4rem;
      }
      @include media_query(medium) {
        gap: 2rem;
      }
      @include media_query(small) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      &.grid_column_style{
        @include media_query(small){
          grid-template-columns: 1fr;
        }
      }
      .badgeinfo {
        min-width: 21rem;
        @include media_query(small) {
          min-width: auto;
        }
        img {
          width: 10rem;
          height: 10rem;
          @include media_query(small) {
            width: 13.9rem;
            height: 13.9rem;
          }
        }
        span {
          padding-top: 1.4rem;
          @extend .montserrat_semibold;
          font-size: 1.3rem;
          color: $black-two;
          @extend .d_block;
          margin: 0 auto 0.4rem;
          line-height: normal;
          @include media_query(small) {
            max-width: 80%;
          }
        }
        p {
          font-size: $fs16;
          color: $black-two;
          @extend .montserrat_regular;
          @include media_query(small) {
            font-size: $fs16;
          }
        }
      }
    }
    h4 {
      margin-bottom: 2.4rem;
      font-size: $fs16;
      @extend .montserrat_bold;
      color: $black-color;
    }
  }
  .small_hr {
    border-color: $popup_border;
  }
  .modal_btn_groups {
    padding-top: 3.2rem;
    gap: 0.8rem;
    @include media_query(extra_small) {
      flex-direction: column;
    }
    .btn_outline {
      padding: 1rem 2.4rem;
      max-width: 20rem;
      @extend .text_center;
      @include media_query(small) {
        padding: 1rem 2rem;
        max-width: 15.7rem;
      }
      @include media_query(extra_small) {
        max-width: 19.3rem;
      }
    }
    .btn_primary {
      padding: 1.2rem 2.4rem;
      max-width: 24.5rem;
      @include trans;
      @include media_query(small) {
        max-width: 19.3rem;
      }
    }
  }
  &.select_badge {
    min-height: 65.1rem;
    @include media_query(small) {
      min-height: auto;
    }
    .selectbadgebox {
      gap: 3.1rem;
      @include media_query(small) {
        flex-direction: column;
        gap: 4.6rem;
      }
      .bedgebox {
        img {
          width: 6.4rem;
          @extend .m_auto;
          &.expert_img {
            width: 14.3rem;
          }
          @include media_query(small) {
            width: auto;
          }
        }
        h6 {
          @extend .montserrat_semibold;
          color: $black-color;
          font-size: 1.3rem;
          margin-block: 1.7rem;
          line-height: normal;
          @include media_query(small) {
            font-size: 2.3rem;
            margin-block: 2.9rem;
          }
        }
        p {
          color: $black-color;
          font-size: 1rem;
          @extend .montserrat_regular;
          @include media_query(small) {
            font-size: 1.7rem;
            font-family: "Montserrat_medium";
            font-weight: $fw500;
            max-width: 25.6rem;
            margin: 0 auto;
            line-height: normal;
          }
        }
      }
    }
  }
  &.personalised {
    // {/* I20D-272 - Remove badges from Progress on Mentor/Mentee pages */}
    // min-height: 65.1rem;
    @extend .position_relative;
    @include media_query(small) {
      min-height: 90rem;
      margin-bottom: 0;
    }
    h4 {
      font-size: $fs18;
      margin-top: 2.2rem;
      @include media_query(small) {
        font-size: $fs20;
        margin-top: 1.6rem;
      }
    }
    .small_hr {
      border-color: $popup_border;
      @include media_query(small) {
        display: none;
      }
    }
    .modal_textbox {
      margin-top: 3rem;
      label {
        @extend .montserrat_bold;
        font-size: $fs14;
        line-height: normal;
        color: $gray-two;
        margin-bottom: 0.8rem;
        @extend .d_block;
      }
      textarea {
        border: 1px solid $btn_disable;
        border-radius: 0.4rem;
        padding: 1.6rem;
        min-height: 14.4rem;
        @include media_query(small) {
          min-height: 12.3rem;
        }
        @extend .w_100;
        font-size: $fs16;
        line-height: 140%;
        @extend .montserrat_regular;
        color: $form-input-color;
        &::placeholder {
          color: inherit;
        }
        &:focus {
          outline: none;
        }
      }
      .err_text {
        color: $error-msg;
      }
    }
    .modal_btn_groups {
      padding-top: 0;
      padding-bottom: 2.4rem;
      @include media_query(small) {
        padding-bottom: 4.5rem;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
      }
    }
  }
  &.video_popup {
    max-width: 102.4rem;
    height: 60rem;
    padding: 0;
    @extend .position_relative;
    border-radius: 3rem;
    overflow: hidden;
    @extend .border_0;
    @include media_query(three_extra_large) {
      margin-right: 10.8rem;
    }
    @include media_query(one_extra_large) {
      margin-right: auto;
    }
    .modal_heading {
      margin-bottom: 0;
      @extend .position_absolute;
      z-index: 1;
      top: 3rem;
      @extend .w_100;
      left: 0;
      right: 0;
      padding: 0 4rem;
      @include media_query(medium) {
        top: 2rem;
        padding: 0 2rem;
        justify-content: space-between;
      }
      h2 {
        color: $white-color;
      }
      .modal_popup_close {
        @include media_query(small) {
          right: 2rem;
          top: 0.5rem;
        }
      }
      img {
        filter: brightness(100);
        @extend .position_relative;
        right: -1.2rem;
      }
    }
    .player__wrapper {
      @extend .w_100;
      @extend .h_100;
      video {
        @extend .position_absolute;
        @extend .w_100;
        @extend .h_100;
        object-fit: cover;
      }
      .top_container {
        h2 {
          @extend .d_none;
        }
      }
      .mid__container {
        @extend .position_absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        @extend .w_100;
        max-width: 40rem;
        @extend .m_auto;
        @extend .d_flex;
        @extend .justify_content_between;
        gap: 11.5rem;
        @include media_query(medium) {
          gap: 5.6rem;
          max-width: 20rem;
        }
        .icon__btn {
          @extend .cursor_pointer;
          img {
            @include media_query(medium) {
              width: 3.2rem;
            }
          }
          // .full_screen_icon{
          //   @extend .position_absolute;
          //   right: 8rem;
          // }
          button {
            img {
              width: 4.2rem;
              height: 6.5rem;
              object-fit: cover;
              @include media_query(medium) {
                width: 2.4rem;
                height: auto;
              }
            }

            .play_btn {
              margin-left: -2.3rem;
              margin-top: 1.2rem;
              @include media_query(medium) {
                margin-top: 0.5rem;
              }
            }
          }
        }
      }
      .bottom__container {
        @extend .position_absolute;
        bottom: 4rem;
        @extend .w_100;
        padding-inline: 4rem;
        left: 0;
        right: 0;
        .control__box {
          padding: 2.9rem 4rem;
          @include media_query(two_extra_large) {
            padding-inline: 3rem;
          }
          @include media_query(extra_large) {
            padding: 1.9rem 2rem;
          }
          @include media_query(medium) {
            padding: 1.6rem;
          }
          @include media_query(extra_small) {
            padding: 1rem;
          }
          background-color: rgba(45, 45, 45, 0.65);
          border-radius: 2rem;
          .inner__controls {
            @extend .d_flex;
            @extend .align_items_center;
            .spanVideo {
              font-size: 2.1rem;
              margin: 0 1rem 0 1rem;
              min-width: 11.8rem;
              @include media_query(two_extra_large) {
                font-size: 1.9rem;
                margin: 0 1.2rem 0 1.2rem;
                min-width: fit-content;
              }
              @include media_query(extra_large) {
                font-size: $fs18;
              }
              @include media_query(medium) {
                font-size: $fs16;
                margin: 0;
              }
              @include media_query(extra_small) {
                font-size: $fs12;
              }
            }
            .progress_bar {
              @extend .d_flex;
              width: 51.4rem;
              margin-right: 4rem;
              @include media_query(two_extra_large) {
                margin-right: 3rem;
                width: 50rem;
              }
              @include media_query(extra_large) {
                margin-right: 2rem;
              }
              @include media_query(large) {
                width: 35rem;
              }
              @include media_query(medium) {
                width: calc(100% - 32% - 32%);
                margin-right: 0;
              }
              @include media_query(small) {
                width: calc(100% - 37% - 38%);
              }
              input {
                @extend .w_100;
              }
            }
            .icon__btn.two_btn {
              @extend .d_flex_align;
              gap: 4rem;
              margin-left: auto;
              @include media_query(two_extra_large) {
                gap: 2rem;
              }
              @include media_query(extra_large) {
                gap: 1rem;
              }
              @include media_query(medium) {
                gap: 2.5rem;
                img {
                  width: 2.4rem;
                }
              }
              @include media_query(small) {
                margin: 0;
              }
              @include media_query(extra_small) {
                gap: 1rem;
              }
            }
            @include media_query(medium) {
              gap: 2.5rem;
              justify-content: space-between;
            }
            @include media_query(small) {
              gap: 1.8rem;
            }
            @include media_query(extra_small) {
              gap: 0.8rem;
            }
          }
        }
        span {
          color: $white-color;
        }
        .action_btn {
          width: 3.8rem;
          height: 3.8rem;
          background-color: $white-color;
          @extend .radius-50;
          display: grid;
          place-items: center;
          @extend .cursor_pointer;
          img {
            width: 1rem;
            filter: brightness(0.3);
            @include media_query(small) {
              width: 0.7rem;
            }
          }
          .play_btn {
            width: 0.8rem;
            border: 0.8rem solid $play_btn;
            @extend .d_block;
            height: 0rem;
            border-radius: 0.4rem;
            transform: rotate(45deg);
            border-left: 0.8rem solid transparent;
            border-bottom: 0.8rem solid transparent;
            margin-left: -0.6rem;
            @include media_query(extra_small) {
              width: 0.6rem;
              border-width: 0.6rem;
              border-radius: 0.3rem;
            }
          }
          @include media_query(small) {
            width: 2.8rem;
            height: 2.8rem;
          }
        }
        @include media_query(large) {
          padding-inline: 2rem;
        }
        @include media_query(medium) {
          bottom: 0.9rem;
          padding-inline: 0.9rem;
        }
      }
    }
    @include media_query(two_extra_large) {
      max-width: 90%;
    }
    @include media_query(medium) {
      height: 40rem;
      margin-bottom: 0;
    }
    &.audio_popup {
      background-color: rgba(45, 45, 45, 0.65);
      backdrop-filter: blur(4.906469821929932px);
      height: 13.6rem;
      max-width: 85.2rem;
      .modal_heading {
        top: 2rem;
        padding: 0 3.2rem;
        h2{
          padding: 0;
          @include media_query(small) {
            padding: 1.4rem 0;
            line-height: normal;
          }
        }
        @include media_query(extra_small) {
          padding-inline: 2rem;
        }
      }
      .modal_popup_close {
        img {
          @include media_query(small) {
            right: -0.6rem;
          }
        }
      }
      .player__wrapper {
        .bottom__container {
          padding-inline: 0;
          background-color: transparent;
          bottom: 0;
          .control__box {
            background-color: transparent;
            padding: 2.4rem 3.2rem;
            @include media_query(extra_small) {
              padding-inline: 2rem;
            }
            .inner__controls {
              @extend .justify_content_between;
              .spanVideo {
                min-width: fit-content;
              }
              .progress_bar {
                @include media_query(medium) {
                  width: 50%;
                }
                @include media_query(small) {
                  width: 30%;
                  margin: 0;
                }
              }
            }
          }
        }
      }
      @include media_query(large) {
        max-width: 65rem;
      }
      @include media_query(medium) {
        max-width: 90%;
        height: 14.9rem;
      }
    }
  }
  &.badge_popup {
     //{/* I20D-272 - Remove badges from Progress on Mentor/Mentee pages */}
    // padding-bottom: 9.6rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    h4 {
      font-size: $fs16;
    }
    .modal_heading{
      margin-bottom: 2rem;
    }
    .badge_scroll {
      max-height: 36rem;
      @extend .custom-scroll;
      overflow-y: auto;
      scrollbar-color: $scroll-color $form-input-active-bg;
      scrollbar-width: thin;
      // @include media_query(small) {
      //   max-height: 41rem;
      // }
      // @include media_query(extra_small) {
      //   max-height: 39rem;
      // }
    }
    .modal_btn_groups {
      @extend .w_100;
      bottom: 3.2rem;
      left: 0;
      right: 0;
      @extend .position_absolute;
      padding-bottom: 0;
    }
    .small_hr {
      @extend .d_block;
    }
  }
  &.badge_continue {
    .selectbadgebox {
      gap: 4rem 1rem;
      @extend .justify_content_center;
      flex-wrap: wrap;
      margin-top: 0.8rem;
      max-height: 46rem;
      @extend .custom-scroll;
      overflow: auto;
      scrollbar-color: $scroll-color $form-input-active-bg;
      scrollbar-width: thin;
      @include media_query(small) {
        max-height: 80vh;
      }
      .bedgebox {
        width: 21rem;
        @extend .text_center;
        min-height: 21rem;
        img {
          @extend .m_auto;
          height: 10.9rem;
          object-fit: cover;
        }
        h6 {
          margin-top: 2.6rem;
          padding-top: 2.4rem;
          @extend .montserrat_semibold;
          font-size: 1.3rem;
          color: $black-two;
          @extend .d_block;
          margin: 0 auto 0.4rem;
          line-height: normal;
        }
      }
    }
    @include media_query(small) {
      padding-bottom: 2rem !important;
      margin-top: 6rem;
      padding-block: 0;
    }
  }
  &.badge_input {
    min-height: auto;
    padding-bottom: 7.6rem;
    @include media_query(small) {
      padding-bottom: 9.6rem;
      // min-height: 100%;
      min-height: 50%;
      .small_hr {
        display: none;
      }
    }
  }
}
.custom_modalbox {
  @extend .position_fixed;
  @extend .w_100;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(22, 35, 83, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
  z-index: 99;
  display: grid;
  place-items: center;
  overflow: hidden;
  @include media_query(small) {
    overflow-y: auto;
    box-shadow: none;
    background-color: $white-color;
  }
}

// @keyframes slideInLeft {
//   0% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//   }
//   100% {
//     -webkit-transform: translateX(60%);
//     transform: translateX(60%);
//   }
// }
// @keyframes slideInRight {
//   0% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//   }
//   100% {
//       -webkit-transform: translateX(-60%);
//       transform: translateX(-60%);
//   }
// }

// @keyframes zoom-in-zoom-out {
//   0% {
//     transform: scale(0.5, 0.5);
//   }
//   100% {
//     transform: scale(1, 1);
//   }
// }
// @-webkit-keyframes zoom-in-zoom-out {
//   0% {
//     -webkit-transform: scale(0.5, 0.5);
//   }
//   100% {
//     -webkit-transform: scale(1, 1);
//   }
// }

// @keyframes zoom-out {
//   0% {
//     transform: scale(1, 1);
//   }
//   100% {
//     transform: scale(0, 0);
//   }
// }
// @-webkit-keyframes zoom-out {
//   0% {
//     -webkit-transform: scale(1, 1);
//   }
//   100% {
//     -webkit-transform: scale(0.5, 0.5);
//   }
// }
// .zoomin_popup {
//   animation: zoom-in-zoom-out 1s ease forwards;
// }
// .zoomout_popup {
//   animation: zoom-out 1s ease forwards;
// }

@keyframes popupRoll {
  0% {
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  100% {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
}
button {
  &:focus-visible {
    outline: none;
  }
}