.mentore_details_main{
  @include media_query(small) {
    margin-top: 5.5rem;
  }
}
.profile_pics {
  padding: 4.2rem 3.2rem;
  background-color: $profile-bg;
  @include media_query(small) {
    padding: 2.7rem 2.5rem;
  }
  .profile_img {
    margin-bottom: -11rem;
    @extend .position_relative;
    z-index: 0;
    @include media_query(small) {
      margin-bottom: -8rem;
      img {
        width: 11rem;
        height: 11rem;
      }
    }
    &:after {
      @extend .position_absolute;
      content: "";
      background-image: url("../images/Union2.png");
      width: 9.1rem;
      height: 10rem;
      bottom: 3.1rem;
      right: -2.8rem;
      background-repeat: no-repeat;
      z-index: -1;
      @include media_query(small) {
        display: none;
      }
    }
  }
  .profile_name{
    @include media_query(small) {
      width: calc(100% - 12rem);
    }
  }
  h2 {
    @extend .montserrat_semibold;
    font-size: $fs32;
    line-height: 110%;
    @extend .position_relative;
    @include media_query(medium) {
      font-size: 2.2rem;
    }
    @include media_query(small) {
      font-size: 3rem;
      padding-left: 1.2rem;
      word-break: break-word;
    }
    @include media_query(extra_small){
      font-size: 2.5rem;
    }
    z-index: 0;
    &:after {
      @extend .position_absolute;
      content: "";
      background-image: url("../images/Union.png");
      width: 9.1rem;
      height: 10rem;
      top: -4.2rem;
      right: -10.2rem;
      background-repeat: no-repeat;
      z-index: -1;
      @include media_query(small) {
        display: none;
      }
    }
  }
}
.profile_box {
  padding: 13rem 3.2rem 5rem;
  @extend .position_relative;
  .status_info {
    ul {
      @extend .list_unstyled;
      gap: 1.6rem;
      @extend .flex_wrap;
      @include media_query(extra_small) {
        gap: 1rem;
      }
      li {
        @extend .montserrat_bold;
        font-size: $fs14;
        color: $gray-two;
        line-height: 140%;
        @extend .d_flex;
        @extend .align_items_center;
        gap: 1.6rem;
        @include media_query(extra_small) {
          font-size: 1.3rem;
        }
        .label_bg {
          @extend .montserrat_regular;
          color: $tab-head;
          padding: 0.4rem 1.2rem;
          border-radius: 0.6rem;
          background-color: $success-color;
          margin-right: 0.6rem;
          @include media_query(extra_small) {
            margin-right: 0;
          }
        }
        .label_date {
          color: $black-two;
        }
      }
    }
  }
  .hr {
    width: 61.2rem;
    .small_hr {
      margin: 3.2rem 0;
    }
    @include media_query(large) {
      width: 100%;
    }
  }
  .profile_info_box {
    h3 {
      @extend .montserrat_semibold;
      font-size: $fs24;
      line-height: 110%;
      margin-bottom: 3.2rem;
      color: $black-color;
    }
    .info_list {
      @extend .list_unstyled;
      width: 61.3rem;
      column-gap: 3.2rem;
      li {
        @extend .montserrat_bold;
        flex-basis: 55%;
        font-size: $fs16;
        color: $black-two;
        margin-bottom: 2.5rem;
        .bold_school_name{
          @extend .montserrat_bold;
          color: $black-color;
          font-size: $fs16;
        }
        span {
          font-size: $fs14;
          line-height: 140%;
          color: $gray-two;
          margin-bottom: 1.3rem;
          @extend .d_block;
        }
        &:nth-child(2n) {
          flex-basis: 35%;
        }
        @include media_query(medium) {
          flex-basis: 100%;
          margin-bottom: 3.2rem;
        }
      }
      @include media_query(large) {
        width: 50.2rem;
        column-gap: 2rem;
      }
      @include media_query(medium) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .form_wrapper{
      @extend .w_100;
      max-width: 61.8rem;
      form{
        .form_group{
          margin-bottom: 2.5rem;
          label{
            color: $tab-text;
            margin-bottom: 1.2rem;
          }
          .selected_value{
            background: #f7fafe;
            border-radius: 0.8rem;
            gap: 0.8rem;
            @extend .h_100;
            @extend .d_flex_align;
            margin-bottom: 0.8rem;
            min-height: 4.8rem;
            padding: 0.8rem 0.8rem 0.8rem 3.5rem;
            @extend .position_relative;
            &:after{
              content: "";
              @extend .position_absolute;
              background-color: $btn_primary;
              height: 0.8rem;
              left: 1.5rem;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              width: 0.8rem;
              border-radius: 50%;
            }
            &.not_selected_value{
              padding-left: 0.8rem;
              &::after{
                content: none;
              }
            }
            p{
              color: $black-two;
              @extend .montserrat_bold;
              font-size: $fs16;
            }  
          }
        }
      }
    }
  }
  @include media_query(small) {
    padding: 6rem 2.5rem 15.6rem;
  }
  @include media_query(extra_small) {
    padding: 5rem 1.5rem 15.6rem;
  }
  &::after{
    @extend .position_absolute;
    content:"";
    background-repeat: no-repeat;
    background-image: url("../images/union_bg.png");
    width: 40rem;
    height: 40rem;
    background-size: contain;
    right: 0;
    top: 17.8rem;
    background-position:right -85px center;
    @include media_query(medium){
      display: none;
    }
  }
  &::before{
    @extend .position_absolute;
    content:"";
    background-repeat: no-repeat;
    background-image: url("../images/union_bg.png");
    width: 28rem;
    height: 28rem;
    left: 0;
    top: 41.6rem;
    background-position: left -98px center;
    background-size: contain;
    @include media_query(large){
      top: 60rem;
    }
    @include media_query(medium){
      display: none;
    }
  }
}
.profile_head {
  @include media_query(medium) {
    .back_home_wrapper {
      display: block;
      position: absolute;
      top: 60px;
    }
  }
}