.sign_box {
  .sign_wrapper {
    padding-top: 7rem;
    @include media_query(small) {
      padding-top: 3rem;
    }
    @extend .position_relative;
    z-index: 0;
    height: calc(100vh - 11.2rem);
    &::before {
      @extend .position_absolute;
      content: "";
      background-image: url("../images/login_bg_logo.png");
      background-repeat: no-repeat;
      background-position: top right -11.8rem;
      width: 54rem;
      height: 54rem;
      top: -2.2rem;
      right: 0;
      z-index: -1;
      background-size: contain;
      @include media_query(three_extra_large) {
        width: 52rem;
        height: 52rem;
        background-position: top right -10rem;
      }
      @include media_query(two_extra_large) {
        background-size: cover;
        background-position: top right -8.8rem;
        width: 40rem;
        height: 40rem;
      }
      @include media_query(large) {
        background-position: top right -7.8rem;
        width: 30rem;
        height: 30rem;
      }
      @include media_query(medium) {
        display: none;
      }
    }
    &::after {
      background-size: contain;
      @extend .position_absolute;
      content: "";
      background-image: url("../images/login_bg_bottom_logo.png");
      background-repeat: no-repeat;
      background-position: bottom left -3.8rem;
      width: 54rem;
      height: 54rem;
      bottom: -2.8rem;
      left: 0;
      z-index: -1;
      @include media_query(three_extra_large) {
        width: 49rem;
        height: 49rem;
      }
      @include media_query(two_extra_large) {
        background-size: contain;
        background-position: bottom left -0.8rem;
        width: 40rem;
        height: 40rem;
        bottom: -2.3rem;
      }
      @include media_query(large) {
        background-position: bottom left -0.7rem;
        width: 30rem;
        height: 30rem;
        bottom: -1.3rem;
      }
      @include media_query(medium) {
        background-image: url("../images/mobile_bg2.png");
        background-position: bottom left;
        width: 30rem;
        height: 30rem;
        bottom: -1.2rem;
      }
    }
    .back_btn {
      @extend .d_none;
      @include media_query(small) {
        display: grid;
        width: 4rem;
        height: 4rem;
        place-items: center;
        border-radius: 10rem;
        border: 0.1rem solid $btn_disable;
      }
    }
    @include media_query(small) {
      .custom-heading {
        h1 {
          margin-top: 0;
          padding-top: 2.5rem;
          font-size: $fs32;
        }
        .sub_head {
          font-size: $fs14;
          line-height: normal;
        }
        margin-bottom: 2.5rem;
      }
    }
    @include media_query(extra_small) {
      .custom-heading {
        h1 {
          font-size: 2.7rem;
        }
      }
    }
    p {
      font-size: $fs20;
      line-height: normal;
      max-width: 93.9rem;
      color: $tab-text;
      @extend .text_justify;
      @extend .montserrat_medium;
      @include media_query(small) {
        font-size: 1.6rem;
        max-width: 100%;
      }
      @include media_query(extra_small) {
        font-size: $fs14;
      }
    }
    .container {
      max-width: 100%;
      padding-inline: 19.8rem;
      @include media_query(two_extra_large) {
        padding-inline: 5rem;
      }
      @include media_query(medium) {
        padding-inline: 3.4rem;
      }
    }
  }
  .sign_footer {
    @extend .position_fixed;
    @extend .w_100;
    bottom: 0;
    left: 0;
    padding: 3.1rem 0;
    border: 0.1rem solid $btn_disable;
    background-color: $white-color;
    .sign_text {
      .footer_text {
        padding-bottom: 0;
        padding-left: 7.9rem;
        @extend .position_absolute;
        top: 50%;
        transform: translateY(-50%);
        @include media_query(two_extra_large) {
          padding-left: 5rem;
        }
        @include media_query(large) {
          padding-left: 3rem;
        }
        @include media_query(medium) {
          position: unset;
          padding-left: 0;
          order: 2;
        }
        a {
          color: $btn_primary;
        }
      }
      .btn_group {
        @extend .d_flex;
        @extend .justify_content_center;
        gap: 0.8rem;
        a {
          max-width: 20.9rem;
          padding: 1rem 3rem;
          @extend .d_flex;
          @extend .align_items_center;
          @extend .justify_content_center;
          &.btn_outline {
            span {
              padding-left: 1.2rem;
            }
            @include media_query(medium) {
              display: none;
            }
          }
          &.btn_primary {
            span {
              padding-right: 1.2rem;
            }
            @include media_query(medium) {
              max-width: 100%;
              @include media_query(medium) {
                svg {
                  display: none;
                }
              }
            }
          }
        }
        @include media_query(large) {
          padding-right: 3rem;
          justify-content: flex-end;
        }
        @include media_query(medium) {
          padding-right: 0;
          justify-content: center;
          margin-bottom: 2.5rem;
        }
      }
      @include media_query(medium) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 0 3.2rem;
        position: relative;
        z-index: 1;
      }
      @include media_query(extra_small) {
        padding: 0 2rem;
      }
    }
    @include media_query(medium) {
      border: 0;
      position: static;
    }
  }
  @extend .position_relative;
  z-index: 0;
  @include media_query (medium){
    &::after {
      position: absolute;
      content: "";
      background-image: url("../images/Logo.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      width: 37.8rem;
      height: 37.8rem;
      top: auto;
      bottom: 0;
      right: 0;
      background-size: contain;
    }
  }
}