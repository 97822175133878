.btn {
  @extend .montserrat_semibold;
  @extend .fs_16;
  line-height: 2.2rem;
  @extend .montserrat_bold;
  font-family: 'Montserrat_bold';
  @extend .text_center;
  @extend .text_capitalize;
  @extend .border_0;
  @extend .radius_5;
  @extend .cursor_pointer;
  min-width: 8.7rem;
  background: $secondary-color;
  color: $white-color;
  padding: 0.9rem 1.5rem;
  @include trans;
  outline: none;
  &:hover {
    background: $secondary-color;
    color: $white-color;
    @include trans;
  }
  &.primary_btn {
    min-width: 17.5rem;
    color: $btn_primary;
    @extend .cursor_pointer;
    font-size: $fs18;
    @extend .montserrat_bold;
    line-height: 150%;
    border-radius: 0.8rem;
    background: $white-color;
    border: 0.2rem solid $btn_primary;
    padding: 1.2rem 2.4rem;

    @extend .justify_content_center;
    &:hover {
      color: $white-color;
    }
  }
  &.resend_btn {
    min-width: 17.5rem;
    color: $white-color;
    @extend .text_center;
    font-size: $fs16;
    @extend .montserrat_bold;
    line-height: 150%;
    border-radius: 0.8rem;
    background: $btn_primary;
    padding: 1.2rem 2.4rem;
    @extend .justify_content_center;
    gap: 1.2rem;
    &:hover {
      color: $white-color;
    }
  }
  &.danger_btn {
    min-width: 17.5rem;
    color: $white-color;
    @extend .text_center;
    font-size: $fs16;
    @extend .montserrat_bold;
    line-height: 150%;
    border-radius: 0.8rem;
    background: $red-required;
    padding: 1.2rem 2.4rem;
    @extend .justify_content_center;
    img {
      filter: brightness(0) invert(1);
      margin-right: 1rem;
    }
  }
  &.assign_btn {
    @extend .d_flex_center;
    @extend .montserrat_bold;;
    @extend .fs_16;
    line-height: 2.2rem;
    @extend .montserrat_bold;
    line-height: 150%;
    color: $btn_primary;
    min-width: 20.9rem;
    background: transparent;
    @include trans;
    &:hover {
      background: $black-color;
      color: $white-color;
    }
    &.primary_btn {
      min-width: 17.5rem;
      color: $btn_primary;
      @extend .text_center;
      font-size: $fs16;
      @extend .montserrat_bold;
      line-height: 150%;
      border-radius: 0.8rem;
      background: $white-color;
      padding: 1.2rem 2.4rem;
      border: 0.2rem solid $btn_primary;
      @extend .justify_content_center;
      @include trans;
      &:hover {
        color: $white-color;
      }
    }
    &.resend_btn {
      min-width: 17.5rem;
      color: $white-color;
      @extend .text_center;
      font-size: $fs16;
      @extend .montserrat_bold;
      line-height: 150%;
      border-radius: 0.8rem;
      background: $btn_primary;
      padding: 1.2rem 2.4rem;
      @extend .justify_content_center;
      @include trans;
      gap: 1.2rem;
      &:hover {
        color: $white-color;
      }
    }
    &.save_btn {
      color: $white-color;
      @extend .text_center;
      font-size: $fs16;
      @extend .montserrat_bold;
      line-height: 150%;
      border-radius: 0.8rem;
      background: $red-required;
      padding: 1.2rem 2.4rem;
      @extend .justify_content_center;
      img {
        filter: brightness(0) invert(1);
      }
    }
    &.assign_btn {
      @extend .d_flex_center;
      @extend .montserrat_bold;;
      @extend .fs_16;
      line-height: 2.2rem;
      @extend .montserrat_bold;
      line-height: 150%;
      color: $btn_primary;
      min-width: 20.9rem;
      background: transparent;
      @include trans;
      &:hover {
        color: $white-color;
      }
      &.save_btn {
        color: $white-color;
        background: $grey-text;
        &.blue_btn{
          background: $btn_primary;
        }
      }
    }
    &.border_btn {
      @extend .fs_16;
      @extend .montserrat_bold;
      line-height: 150%;
      min-width: 27.4rem;
      color: $gray-color;
      @extend .text_center;
      @extend .radius_8;
      background: $white-color;
      border: 0.2rem solid $btn_primary;
      padding: 1.2rem 2.4rem;
      @extend .justify_content_center;
      @include trans;
      &:hover {
        border: 0.2rem solid transparent;
        color: $white-color;
      }
      &.add_btn{
        color: $white-color;
        background: $grey-text;
        border: transparent;
        &.add_plus{
          background: $btn_primary;
        }
      }
    }
    &.unselect_btn{
      @extend .d_flex_center;
      @extend .montserrat_bold;;
      @extend .fs_16;
      line-height: 2.2rem;
      @extend .montserrat_bold;
      line-height: 150%;
      color: $gray-color;
      min-width: 20.9rem;
      background: transparent;
      border: 0.2rem solid $btn_primary;
      &:hover{
        color: $gray-color;
        &::after{
            opacity: 0;
        }
      }
    }
  }
  &.border_btn {
    @extend .fs_16;
    @extend .montserrat_bold;
    line-height: 150%;
    min-width: 27.4rem;
    color: $gray-color;
    @extend .text_center;
    @extend .radius_8;
    background: $white-color;
    border: 0.2rem solid $btn_primary;
    padding: 1.2rem 2.4rem;
    @extend .justify_content_center;
    &:hover {
      background: $btn_primary;
      border: 0.2rem solid transparent;
      color: $white-color;
    }
    &.add_btn{
      color: $white-color;
      background: $grey-text;
      border: transparent;
      &.add_plus{
        background: $btn_primary;
      }
    }
  }
  &.unselect_btn {
    @extend .d_flex_center;
    @extend .montserrat_bold;;
    @extend .fs_16;
    line-height: 2.2rem;
    @extend .montserrat_bold;
    line-height: 150%;
    color: $gray-color;
    min-width: 20.9rem;
    background: transparent;
    border: 0.2rem solid $btn_primary;
    &:hover {
      color: $gray-color;
      &::after {
        opacity: 0;
      }
    }
  }
}

.upload_img {
  @include button;
  font-size: $fs16;
  line-height: 140%;
  max-width: 18.7rem;
  color: $gray-color;
  @extend .w_100;
  border: 0.2rem solid $btn_primary;
  margin: 0;
  &:hover {
    color: $white-color;
    background-color: $btn_primary;
  }
}

.blue_border_btn {
  @extend .fs_16;
  @extend .montserrat_bold;
  line-height: 150%;
  min-width: 27.4rem;
  color: $gray-color;
  @extend .text_center;
  @extend .radius_8;
  background: $white-color;
  border: 0.2rem solid $btn_primary;
  padding: 1.2rem 2.4rem;
  @extend .justify_content_center;
  @extend .text_capitalize;
  @extend .border_0;
  @extend .cursor_pointer;
  @include trans;
  &:hover {
    background: $btn_primary;
    border: 0.2rem solid transparent;
    color: $white-color;
  }
  &.add_btn {
    color: $white-color;
    background: $grey-text;
    border: transparent;
    &.add_plus {
      background: $btn_primary;
    }
  }
}

// primary button
.btn_primary {
  background-color: $btn_primary;
  color: $white-color;
  font-size: $fs16;
  font-family: "Montserrat_bold";
  border-radius: 8px;
  border: transparent;
  padding: 1.8rem 2rem;
  @extend .w_100;
  max-width: 28.6rem;
  @extend .d_inline_block;
  text-decoration: none;
  @include trans;
  &:hover {
    color: $white-color;
  }
  &:active {
    color: $white-color;
  }
  &.disable {
    background-color: $btn_disable !important;
  }
}
// outline button
.btn_outline {
  border: 0.2rem solid $btn_primary;
  background-color: transparent;
  color: $gray-color;
  font-size: $fs16;
  @extend .fw_700;
  font-family: 'Montserrat_bold';
  border-radius: 0.8rem;
  padding: 1.8rem 6.9rem;
  @extend .w_100;
  max-width: 27.5rem;
  @include trans;
  &:hover {
    background-color: $btn_primary;
  }
  &:active {
    background-color: $btn_primary;
  }
  &.disable {
    color: $btn_disable;
    background-color: transparent;
    border: 0.1rem solid $gray-four;
  }
}
// icon button
.icon_text_btn {
  background-color: $btn_primary;
  color: $white-color;
  font-size: $fs16;
  font-family: "Montserrat_bold";
  border-radius: 0.8remk;
  border: transparent;
  padding: 1.6rem 5.3rem;
  @extend .w_100;
  max-width: 28.3rem;
  @extend .d_flex_center;
  @include trans;
  span {
    padding-left: 1.6rem;
  }
  &.disable {
    background-color: $btn_disable;
  }
}
// only icon button
.icon_btn {
  background-color: $btn_primary;
  @extend .w_100;
  max-width: 5.6rem;
  min-height: 5.6rem;
  border: transparent;
  border-radius: 50%;
  padding: 1.6rem;
  @extend .d_flex_center;
  @include trans;
  &.disable {
    background-color: $btn_disable;
    svg {
      path {
        stroke: $gray-four;
      }
    }
  }
}
// secondary button
.btn_secondary {
  background-color: $btn_primary;
  color: $white-color;
  font-size: $fs16;
  @extend .montserrat_bold;;
  border-radius: 0.8rem;
  border: transparent;
  padding: 1.8rem 8rem;
  @extend .w_100;
  max-width: 28.6rem;
  @include trans;
  &:hover {
    background-color: $primary-color;
  }
  &:active {
    color: $gray-color;
  }
}
.btn_small {
  @extend .w_100;
  max-width: 24.1rem;
  padding: 1.4rem 7rem;
}
.btn_medium {
  @extend .w_100;
  max-width: 31rem;
  padding: 1.9rem 9rem;
}
.btn_large {
  @extend .w_100;
  max-width: 34.4rem;
  padding: 2.4rem 10rem;
}
.link_btn {
  color: $btn_primary;
  @extend .montserrat_medium;
  font-size: $fs16;
  line-height: 140%;
  border-bottom: 0.1rem solid;
  margin-left: 0.5rem;
  cursor: pointer;
  &:hover {
    color: $primary-color;
    @extend .montserrat_semibold;
  }
}