.content_wrapper {
  padding: 0 3.2rem 3rem;
  display: grid;
  grid-template-columns: 3.5fr 1fr;
  gap: 3.2rem;
  &.detailed_wrapper {
    grid-template-columns: 1fr;
    @include media_query(medium) {
      .content_list {
        .content_list_info {
          .content_box_wrapper {
            gap: 2.5rem;
            display: grid;
          }
        }
      }
    }
    @include media_query(small){
      padding-top: 13rem;
    }
  }
  @include media_query(large) {
    grid-template-columns: 1fr;
  }
  @include media_query(medium) {
    padding: 0 2rem 3rem;
  }
  @include media_query(small) {
    padding-inline: 2.5rem;
    display: block;
    padding-top: 5.5rem;
  }
  @include media_query(extra_small) {
    padding-inline: 2rem;
  }
  .comman_head {
    padding-inline: 0;
    h3 {
      @include media_query(medium) {
        font-size: $fs20;
      }
      @media screen and (max-width: 429px) {
        font-size: $fs18;
      }
      @include media_query(extra_small) {
        font-size: $fs16;
      }
      span {
        @extend .montserrat_regular;
      }
    }
    @include media_query(medium) {
      .link_btn {
        display: none;
      }
      padding-bottom: 1.5rem;
    }
    @include media_query(small) {
      .link_btn {
        font-size: $fs14;
        margin-left: 1rem;
      }
    }
    @include media_query(extra_small) {
      .link_btn {
        font-size: $fs12;
        margin-left: 0.5rem;
      }
    }
    .content_fixed_header{
      @include media_query(small){
        position: fixed;
        top: 55px;
        width: 100%;
        background-color: $white-color;
        z-index: 100;
      }
    }
  }
  .content_list {
    .content_list_info {
      @include media_query(small) {
        margin-bottom: 0rem;
      }
      margin-bottom: 1rem;
      .content_box_wrapper {
        gap: 3.2rem;
        @extend .d_flex;
        @extend .flex_wrap;
        @include media_query(three_extra_large) {
          gap: 3rem;
        }
        @include media_query(two_extra_large) {
          gap: 2rem;
        }
        @include media_query(large) {
          gap: 2.5rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
        @include media_query(medium) {
          grid-template-columns: 1fr;
        }
        @include media_query(small) {
          display: block;
          width: 100%;
          .slick-slider {
            padding-right: 8rem;
            .slick-arrow {
              display: none;
            }
            .slick-list {
              overflow: visible;
              .slick-track {
                margin: 0 -1.5rem;
                .slick-slide {
                  padding: 0 1.5rem;
                }
              }
            }
          }
          &::-webkit-scrollbar {
            width: 0.6rem;
            height: 0rem;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 0rem;
          }
          &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 0rem;
          }
        }
        @include media_query(extra_small) {
          .slick-slider {
            padding-right: 2rem;
          }
        }
        .content_box {
          width: 26rem;
          min-height: auto;
          // padding-bottom: 14rem;
          &.training_content_box {
            padding-bottom: 7.6rem;
          }
          @include media_query(one_extra_large) {
            width: 25rem;
          }
          @include media_query(extra_large) {
            width: 30rem;
          }
          @include media_query(large) {
            width: 100%;
          }
          @include media_query(medium) {
            width: 30rem;
          }
          @include media_query(small) {
            width: 100%;
          }
          @extend .box_border;
          background-color: $white-color;
          @extend .position_relative;
          .trunated  {
            background-color: $dark-bg;
            color: $white-color;
            left: 1rem;
            right: 1rem;
          }
          .content_img {
            @extend .position_relative;
            img {
              @extend .w_100;
              border-top-left-radius: 0.8rem;
              border-top-right-radius: 0.8rem;
              &.content_img_box {
                height: 18rem;
                object-fit: cover;
              }
            }
            .dot_icon_modal {
              @extend .position_absolute;
              top: 1.2rem;
              right: 1.2rem;
              @extend .cursor_pointer;
              .dot_icon {
                width: 4rem;
                height: 4rem;
                background-color: $white-color;
                display: grid;
                place-items: center;
                box-shadow: 0px 0px 30px 0px rgba(36, 73, 137, 0.15);
                -webkit-box-shadow: 0px 0px 30px 0px rgba(36, 73, 137, 0.15);
                @extend .radius-50;
                @include trans;
                img {
                  transform: rotate(90deg);
                  -webkit-transform: rotate(90deg);
                  height: auto;
                }
                &:hover {
                  background-color: $primary-color;
                  img {
                    filter: brightness(10);
                  }
                }
              }
              &.active {
                .dot_icon {
                  background-color: $primary-color;
                  img {
                    filter: brightness(10);
                  }
                }
              }
            }
          }
          h6 {
            @extend .montserrat_semibold;
            margin-bottom: 1rem;
            font-size: $fs18;
            word-break: break-word;
            height: 4.4rem;
            overflow: hidden;
            // &:hover{
            //    + span{
            //     &:hover{
            //       display: block;
            //     }
            //     display: block;
            //   }
            // }
          }
          span {
            font-size: $fs14;
            @extend .montserrat_regular;
            color: $gray-three;
          }
          .conect_info {
            padding: 2.3rem 2rem 1.7rem;
            @extend .over_hidden;
            .content_bottom {
              @extend .position_absolute;
              bottom: 2rem;
              left: 0;
              @extend .w_100;
              right: 0;
              padding-top: 1.6rem;
              border-top: 1px solid $btn_disable;
              .author_img {
                padding-inline: 2rem;
                img {
                  object-fit: cover;
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                  @extend .radius-50;
                  width: 4rem;
                  height: 4rem;
                }
                span {
                  @extend .montserrat_medium;
                  padding-left: 1rem;
                }
              }
            }
            &.project_content {
              .connect_head {
                h6 {
                  @extend .cursor_pointer;
                  min-height: 6.5rem;
                }
              }
              .custom_progressbar {
                bottom: 2.1rem;
                border: 0;
                .progress_bar_box {
                  padding-inline: 2rem;
                  .bar_info {
                    span {
                      @extend .montserrat_medium;
                      color: $black-color;
                    }
                  }
                  .progress_design {
                    .progress_fill {
                      background-color: $btn_primary;
                    }
                  }
                }
              }
            }
            .event_file {
              padding: 0 0 1.6rem;
              margin-top: 1.6rem;
              // bottom: 7.6rem;
              bottom: 0;
            }
          }
          .dot_menu_modal {
            box-shadow: rgba(115, 115, 115, 0.15) 0px 0px 30px 0px;
            min-width: 20.9rem;
            padding-bottom: 0px;
            @extend .position_absolute;
            right: 0.8rem;
            width: auto;
            z-index: 1;
            background: $white-color;
            border-width: 0.1rem;
            border-style: solid;
            border-color: $btn_disable;
            border-image: initial;
            border-radius: 0.4rem;
            .list_dot_menu {
              @extend .d_flex_align;
              justify-content: flex-start;
              padding: 1.6rem;
              @extend .montserrat_medium;
              font-size: $fs16;
              color: $gray-color;
              @include media_query(extra_small) {
                padding: 1.2rem;
                font-size: $fs14;
              }
              &:hover {
                background-color: $form-input-active-bg;
              }
              img {
                width: 1.6rem;
              }
            }
          }
          &.disable {
            pointer-events: none;
            cursor: no-drop;
            @extend .position_relative;
            &::after {
              @extend .position_absolute;
              content: "";
              width: 100%;
              height: 100%;
              background-color: $content-disable;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: 1;
              border-radius: 0.8rem;
            }
          }
          -webkit-tap-highlight-color: transparent;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .training_list {
    @extend .h_100;
    min-width: 27.6rem;
    margin-left: auto;
    @include media_query(three_extra_large) {
      min-width: 27.6rem;
    }
    @include media_query(large) {
      margin-left: 0;
      min-width: 27.6rem;
      max-width: 38rem;
    }
    @include media_query(medium) {
      min-width: 38rem;
      display: flex;
      flex-wrap: wrap;
      min-width: auto;
      flex-direction: column;
    }
    .training_wrapper {
      height: 85rem;
      padding: 2.5rem;
      @extend .custom-scroll;
      scrollbar-width: thin;
      scrollbar-color: $scroll-color $form-input-active-bg;
      overflow-y: auto;
      @extend .box_border;
      @extend .custom_box_shadow;
      padding: 2.5rem;
      @include media_query(small) {
        border: 0;
        padding: 0;
        box-shadow: none;
      }
      .training_box {
        @extend .box_border;
        border-width: 1.5px;
        padding: 1.5rem;
        @extend .custom_box_shadow;
        @extend .d_flex;
        flex-direction: column;
        align-items: start;
        margin-bottom: 1.5rem;
        @include media_query(small) {
          box-shadow: none;
        }
        .status {
          padding: 0.3rem 0.9rem;
          border-radius: 0.4rem;
          background-color: $status_green;
          @extend .d_inline_block;
          margin-bottom: 1.5rem;
          &.light_yellow {
            background-color: $light_yellow;
          }
          &.light_green {
            background-color: $success-color;
          }
          span {
            color: $tab-head;
            font-size: $fs14;
            @extend .montserrat_regular;
          }
        }
        strong,
        .c_date {
          @extend .montserrat_semibold;
          font-size: $fs14;
          color: $black-color;
          line-height: normal;
        }
        .c_date {
          @extend .montserrat_regular;
        }
        .btn {
          margin-top: 1.5rem;
          font-size: 1.1rem;
          @extend .montserrat_bold;
          border-radius: 0.5rem;
          @extend .justify_content_center;
          @extend .w_100;
          &.border_btn {
            color: $gray-color;
            min-width: auto;
            background: $white-color;
            &:hover {
              border-color: $primary-color;
              color: $white-color;
            }
          }
        }
        &.disable {
          pointer-events: none;
          cursor: no-drop;
          @extend .position_relative;
          &::after {
            @extend .position_absolute;
            content: "";
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            border-radius: 0.8rem;
          }
        }
      }
    }
  }
  .custom_modalbox{
    .video_popup  {
      .modal_heading{
        opacity: 0;
        transition: all 1s ease-out;
      }
      .mid__container{
        opacity: 0;
        transition: all 1s ease-out;
      }
      .bottom__container{
        opacity: 0;
        transition: all 1s ease-out;
      }
      &:hover{
        .modal_heading {
          opacity: 1;
        }
        .mid__container{
          opacity: 1;
        }
        .bottom__container{
          opacity: 1;
        }
      }
    }
    .audio_popup {
      .progress_bar{
        margin-left: 4rem;
      }
      .modal_heading{
        opacity: 1;
      }
      .mid__container{
        opacity: 1;
      }
      .bottom__container{
        opacity: 1;
      }
    }
  }
}
.sharepopup {
  max-width: 62.4rem;
  box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
  -webkit-box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
  margin-bottom: 0;
  padding-bottom: 15.4rem;
  @include media_query(large) {
    max-width: 64.2rem;
  }
  @include media_query(medium) {
    width: 85%;
    max-width: 100%;
  }
  @include media_query(small) {
    max-width: 90%;
    padding-inline: 0;
    padding-bottom: 3rem;
  }
  @extend .position_relative;
  .share_head {
    margin-bottom: 1.1rem;
    h2 {
      line-height: normal;
      font-size: $fs18;
    }
    .close_btn {
      width: 4rem;
      @extend .position_absolute;
      right: 2rem;
      top: 2.2rem;
      @extend .cursor_pointer;
      @include media_query(small){
        width: 3rem;
        top: 3rem;
        right: 1.5rem;
      }
    }
    .back_btn {
      @extend .position_absolute;
      left: 3.2rem;
      top: 3.2rem;
      @include media_query(small) {
        left: 2.5rem;
      }
      p {
        font-size: $fs18;
        line-height: 2.5rem;
        @extend .montserrat_medium;
        margin-left: 2rem;
        color: $black-color;
      }
    }
  }
  .chat_grp {
    margin-bottom: 2.1rem;
    margin-top: 3rem;
    .image_wrapper {
      margin-right: 1.4rem;
      @include media_query(small) {
        margin-right: 1rem;
      }
      img {
        width: 4.1rem;
        height: 4.1rem;
        object-fit: cover;
        @extend .radius-50;
        @include media_query(small) {
          width: 4.3rem;
          height: 4.3rem;
        }
        @include media_query(extra_small) {
          width: 4rem;
          height: 4rem;
        }
      }
      &.single_img {
        img {
          &.second_user,
          &.third_user {
            @extend .d_none;
          }
        }
      }
      &.dual_img {
        @extend .position_relative;
        z-index: 1;
        width: 5.5rem;
        @extend .d_flex;
        img {
          &.second_user {
            @extend .position_absolute;
            right: 0;
            z-index: -1;
          }
          &.third_user {
            @extend .d_none;
          }
        }
      }
      &.group_img {
        @extend .position_relative;
        z-index: 1;
        width: 5.5rem;
        img {
          @extend .position_absolute;
          right: 0;
          z-index: -1;
          &.first_user {
            top: -3rem;
            z-index: 1;
            right: 0.6rem;
          }
          &.second_user {
            top: -2.2rem;
            right: -0.1rem;
            z-index: 0;
          }
          &.third_user {
            right: -0.5rem;
            top: -2.9rem;
          }
        }
      }
    }
    p {
      font-size: $fs16;
      @extend .montserrat_semibold;
      color: $black-color;
      @include media_query(extra_small) {
        font-size: 1.3rem;
      }
    }
  }
  .chatgrp_area {
    padding-inline: 1rem;
    max-height: 28.1rem;
    @include media_query(small) {
      padding: 0 2.4rem;
      max-height: 100%;
    }
    .shared_msg_box {
      @extend .position_relative;
      bottom: unset;
      .landscape_view{
        img{
          max-width: 26rem;
          max-height: 18rem;
        }
        p {
          line-height: normal;
        }
        @include media_query(small){
          img {
            max-width: 100%;
            width: 100%;
            max-height: 18rem;
            object-fit: cover;
          }
        }
      }
      @include media_query(small) {
        padding-inline: 1.6rem;
        padding-bottom: 1.6rem;
      }
    }
  }
  .chat_footer {
    border: 0;
    position: absolute;
    bottom: 3.2rem;
    left: 0;
    @include media_query(small) {
      bottom: 0;
      position: unset;
    }
    .chat_footer_box {
      background-color: $white-color;
      @include media_query(small){
        bottom: 11rem;
      }
    }
  }
}
.play_btn {
  width: 5rem;
  border: 2.5rem solid $white-color;
  @extend .d_block;
  height: 5rem;
  border-radius: 0.5rem;
  transform: rotate(45deg);
  border-left: 2.5rem solid transparent;
  border-bottom: 2.5rem solid transparent;
  &:focus-visible {
    outline: none;
  }
  @include media_query(medium) {
    width: 2.5rem;
    border-width: 1.5rem;
    height: 2.5rem;
    border-left-width: 1.5rem;
    border-bottom-width: 1.5rem;
  }
}
