// spacing
@mixin space {
  margin: 0;
  padding: 0;
}
@mixin trans {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

@mixin button {
  max-width: 249px;
  width: 100%;
  padding: 12px 24px;
  border-radius: 8px;
  border: unset;
  font-size: 16px;
  @extend .montserrat_bold;
  font-weight: 700;
  line-height: 150%;
  color: $white-color;
  background: $white-color;
  @extend .d_flex_center;
  margin: 8px 8px 8px 0;
  transition: 0.5s all;
  img {
    margin-right: 12px;
  }
}

@mixin back_btn {
  button {
    font-size: 18px;
    @extend .montserrat_medium;
    font-weight: 500;
    line-height: 140%;
    .back_btn_img {
      border: 1px solid #4f4f4f;
      border-radius: 50px;
      width: 24px;
      margin-right: 20px;
      padding: 2px;
    }
  }
}

@mixin profile_small($properties...) {
  @each $property in $properties {
    $property-name: nth($property, 1);
    $property-value: nth($property, 2);
    
    #{$property-name}: $property-value;
  }
}

// @include profile_small(
//     (max-width, 2.4rem),
//     (max-height, 2.4rem),
//     (border-radius, 5rem)
//   );

//more
@mixin plus_more {
  @extend .fs_12;
  @extend .montserrat_bold;
  line-height: 140%;
  color: $secondary-color;
  text-decoration: underline;
  @extend .cursor_pointer;
}
//step-count
@mixin step-count {
  width: 24px;
  height: 24px;
  @extend .radius-50;
  border: 2px solid $dark-bg;
  @extend .fs_16;
  @extend .montserrat_bold;
  color: $dark-bg;
  @extend .d_flex;
  @extend .justify_content_center;
  @extend .align_items_center;
  margin-right: 1.6rem;
  &.active {
    background-color: $dark-bg;
    color: $white-color;
  }
  &.disable {
    background-color: $separtor-color;
    color: $white-color;
    border-color: $separtor-color;
  }
}

@mixin tooltip {
  color: $white-color;
  @extend .fs_14;
  @extend .fw_400;
  line-height: 140%;
  padding: 0.8rem 1rem;
  @extend .radius_4;
  background: $primary-color;
  @extend .d_flex_align;
  gap: 1rem;
  @extend .position_absolute;
  top: -3.7rem;
  left: -2rem;
  opacity: 0;
  display: none;
  @extend .white_space_nowrap;
  @extend .hover_transition;
}

$animate: popup_animation 0.4s linear forwards;
// Popup Animation
@mixin popup_animate() {
  animation: $animate;
  @keyframes popup_animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin media_query($breakpoint) {
  $max-width: map-get($breakpoints, $breakpoint);

  @if $max-width {
    @media screen and (max-width: $max-width) {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint #{$breakpoint}.";
  }
}

$known-prefixes: (webkit, moz, ms, o);

@mixin rotate-keyframes($prefix, $duration: 5s) {
  @keyframes rotate-#{$prefix} {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @for $i from 1 through 5 {
    $increased-speed: $duration * $i;
    @keyframes rotate-#{$prefix}-increased-#{$i * 5} {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @for $j from 0 through 100 {
      $increased-speed-step: $increased-speed / 100 * $j;
      @keyframes rotate-#{$prefix}-increased-#{$i * 5}-#{$j} {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}

@mixin prefix($property, $value, $prefixes) {
  $animation-duration: 5s;

  @each $prefix in $prefixes {
    @if not index($known-prefixes, $prefix) {
      @warn "Unknown prefix #{$prefix}.";
    }

    -#{$prefix}-#{$property}: $value;
    animation: rotate-#{$prefix}
      $animation-duration
      ease-in-out
      infinite
      forwards;

    @include rotate-keyframes($prefix, $animation-duration);
  }

  #{$property}: $value;
}
