.nodata_found {
  width: 100%;
  max-width: 30.4rem;
  @extend .m_auto;
  @extend .position_absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .search_page{
    width: 12rem;
    height: 12rem;
    @extend .radius_round;
    @extend .d_flex_center;
    @extend .m_auto;
    background: $form-input-active-bg;
    margin-bottom: 2.4rem;
    
  }
  h5 {
    @extend .fs_24;
    @extend .fw_600;
    @extend .text_center;
    line-height: 110%;
    color: $tab-head;
    margin-bottom: 1.6rem;
  }
  .find_page_content {
    @extend .fs_16;
    @extend .fw_400;
    @extend .text_center;
    line-height: 140%;
    color: $gray-three;
  }
  &.no_mentee_found{
    .search_page{
      img{
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }
}