// width
.w_100 {
  width: 100%;
}
.h_100 {
  height: 100%;
}

// Positions
.position_fixed {
  position: fixed;
}

.position_relative {
  position: relative;
}

.position_absolute {
  position: absolute;
}

// General
.border_0 {
  border: 0;
}

.radius-50 {
  border-radius: 10rem;
}

.cursor_pointer {
  cursor: pointer;
}

// Text
.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.text_uppercase {
  text-transform: uppercase;
}

.text_lowercase {
  text-transform: lowercase;
}

.text_capitalize {
  text-transform: capitalize;
}

.text_decoration_none {
  text-decoration: none;
}

.radius_4 {
  border-radius: 0.4rem;
}
.radius_5 {
  border-radius: 0.5rem;
}
.radius_6 {
  border-radius: 0.6rem;
}
.radius_8 {
  border-radius: 0.8rem;
}
.radius_20 {
  border-radius: 2rem;
}
.radius_30 {
  border-radius: 3rem;
}
.radius_round {
  border-radius: 100%;
}

// Display Properties
.d_block {
  display: block;
}

.d_inline_block {
  display: inline-block;
}

.d_none {
  display: none;
}

.d_flex {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.d_grid {
  display: grid;
}

.d_inline_flex {
  display: inline-flex;
}

.d_flex_align {
  @extend .d_flex;
  @extend .align_items_center;
}

.align_items_center {
  align-items: center;
}

.align_items_end {
  align-items: flex-end;
}

.align_items_start {
  align-items: flex-start;
}

.justify_content_center {
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
}

.justify_content_between {
  justify-content: space-between;
}

.justify_content_around {
  justify-content: space-around;
}

.justify_content_evenly {
  justify-content: space-evenly;
}

.justify_content_start {
  justify-content: flex-start;
}

.justify_content_end {
  justify-content: flex-end;
}

.font-italic {
  font-style: italic;
}
.d_flex_center {
  @extend .d_flex;
  @extend .align_items_center;
  @extend .justify_content_center;
}

.d_flex_between {
  @extend .d_flex;
  @extend .align_items_center;
  @extend .justify_content_between;
}

.flex_direction_column {
  flex-direction: column;
}

.nowrap {
  flex-wrap: nowrap;
}

.flex_wrap {
  flex-wrap: wrap;
}

.word_break {
  word-break: break-all;
}

.white_space_nowrap {
  white-space: nowrap;
}

.white_space_wrap {
  white-space: normal;
}

// Color
.bg_sky_blue{
  background: #f3f8fe;
}

.bg_blue_profile{
  background-color: #DCEAFB;
}


//   container
.container {
  @extend .w_100;
  max-width: 141rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @extend .m_auto;
}
.form_container {
  @extend .w_100;
  max-width: 66.4rem;
}

// Form
.form_group {
  @extend .position_relative;
  margin-bottom: 3.2rem;

  label {
    font-size: $fs14;
    color: $gray-color;
    @extend .d_block;
    @extend .montserrat_bold;
  }
  input {
    @extend .montserrat_regular;
    color: $black-two;
  }
  .input_remove,
  .error_icon {
    width: 2rem;
    @extend .d_flex;
    @extend .justify_content_end;
    @extend .position_absolute;
    top: 4.8rem;
    right: 1.6rem;
    background-color: $white_color;
  }
  .error_icon {
    top: 4.5rem;
    right: 2rem;
    @include media_query(medium) {
      justify-content: flex-end !important;
      width: 1.6rem;
    }
  }
  .react-datepicker-wrapper {
    @extend .w_100;
  }
  .react-datepicker-wrapper {
    @extend .w_100;
    .react-datepicker__input-container {
      input {
        background: url(../images/Calendar.svg) right 1.6rem center no-repeat;
        background-repeat: no-repeat;
        @extend .cursor_pointer;
      }
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      @extend .w_100;
      .react-datepicker {
        @extend .w_100;
        min-height: 29.5rem;
        padding: 1.6rem;
        border: 0.1rem solid $separtor-color;
        .react-datepicker__header {
          background-color: $white-color;
          border: 0 !important;
        }
        .react-datepicker__month-container {
          float: inherit;
          .react-datepicker__current-month {
            font-size: $fs14;
            padding: 0.5rem 0 1.2rem;
          }
          .react-datepicker__day-names {
            @extend .d_flex_between;
            margin: 0;
            gap: 1rem;
            .react-datepicker__day-name {
              font-size: $fs12;
              width: 2.5rem;
            }
          }
          .react-datepicker__day {
            width: 2.5rem;
            font-size: $fs12;
            border-radius: 10rem;
            border: 0.1rem solid transparent;
            padding: 0.3rem 0;
            @extend .cursor_pointer;
            &:hover {
              border-radius: 10rem;
              background-color: transparent;
              border: 0.1rem solid $btn_primary;
              color: $tab-head;
              padding: 0.3rem 0;
              width: 2.5rem;
            }
            &.react-datepicker__day--today {
              background-color: $btn_primary;
              color: $white-color;
            }
          }
          .react-datepicker__week {
            @extend .d_flex_between;
            gap: 1rem;
            padding-bottom: 0.7rem;
            .react-datepicker__day--keyboard-selected {
              background-color: $btn_primary;
              color: $white-color;
            }
            .react-datepicker__day--selected {
              border-radius: 10rem;
              background-color: transparent;
              border: 0.1rem solid $btn_primary;
              color: $tab-head;
            }
          }
        }
      }
      .react-datepicker__navigation--previous {
        top: 1rem;
      }
      .react-datepicker__navigation-icon--previous {
        overflow: unset !important;
      }
      .react-datepicker__navigation-icon--next {
        overflow: unset !important;
        top: 1rem;
      }
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__tab-loop {
    + .err_field {
      .error_icon {
        padding: 0.5rem;
        top: 3.9rem;
      }
    }
  }

  .react-datepicker__header__dropdown {
    padding-bottom: 1.5rem;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    padding: 0.3rem 0.2rem;
    font-size: $fs14;
  }
}

.form_control {
  @extend .radius_4;
  min-height: 4.8rem;
  @extend .w_100;
  border: 0.1rem solid $separtor-color;
  padding: 0 1.6rem;
  font-size: 1.6rem;
  outline: unset;
}
// Unorderded List : UL/LI
.list_unstyled {
  list-style: none;
}

// Image
.img_object_center {
  object-fit: cover;
  object-position: center;
}

.img_fluid {
  max-width: 100%;
}
.m_auto {
  margin: 0 auto;
}
.mb_15 {
  margin-bottom: 1.5rem;
}
.mb_20 {
  margin-bottom: 2rem;
}
.mb_50 {
  margin-bottom: 5rem;
}
.mt_50 {
  margin-top: 5rem;
}
.pt_100 {
  padding-top: 10rem;
}
.pb_100 {
  padding-bottom: 10rem;
}
.w_50 {
  width: 50%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.fs_12 {
  font-size: 1.2rem;
}
.fs_14 {
  font-size: 1.4rem;
}
.fs_15 {
  font-size: 1.5rem;
}
.fs_16 {
  font-size: 1.6rem;
}
.fs_18 {
  font-size: 1.8rem;
}
.fs_20 {
  font-size: 2rem;
}
.fs_22 {
  font-size: 2.2rem;
}
.fs_24 {
  font-size: 2.4rem;
}
.fs_26 {
  font-size: 2.6rem;
}
.fs_28 {
  font-size: 2.8rem;
}
.fs_30 {
  font-size: 3rem;
}
.fs_32 {
  font-size: 3.2rem;
}
.fs_40 {
  font-size: 4rem;
}

// Gap Properties
.gap_4 {
  gap: 0.4rem;
}

.gap_8 {
  gap: 0.8rem;
}

.gap_10 {
  gap: 1rem;
}

.gap_12 {
  gap: 1.2rem;
}

.gap_20 {
  gap: 2rem;
}
.gap_22 {
  gap: 2.2rem;
}
.gap_24 {
  gap: 2.4rem;
}
.gap_30 {
  gap: 3rem;
}

// font Weight
.fw_400 {
  @extend .montserrat_regular;
}
.fw_500 {
  @extend .montserrat_medium;
}
.fw_600 {
  @extend .montserrat_semibold;
}
.fw_700 {
  @extend .montserrat_bold;
}
.fw_900 {
  @extend .montserrat_black;
}

// Margin
.m_0 {
  margin: 0;
}

.m_24{
  margin: 2.4rem;
}

.ml_0 {
  margin-left: 0;
}
.mr_0 {
  margin-right: 0;
}
.mb_0 {
  margin-bottom: 0;
}
.mt_0 {
  margin-top: 0;
}

.m_auto {
  margin: 0 auto;
}
.ml_10 {
  margin-left: 1rem;
}
.mr_10 {
  margin-right: 1rem;
}
.mr_16 {
  margin-right: 1.6rem;
}
.ml_05 {
  margin-left: 0.5rem;
}
.mb_15 {
  margin-bottom: 1.5rem;
}
.mb_20 {
  margin-bottom: 2rem;
}
.mb_10 {
  margin-bottom: 1rem;
}
.mb_40 {
  margin-bottom: 4rem;
}
.mb_4 {
  margin-bottom: 0.4rem;
}

// Padding
.p_0 {
  padding: 0;
}

.pb_15 {
  padding-bottom: 1.5rem;
}

.p_24{
  padding: 2.4rem;
}

.pl_0 {
  padding-left: 0;
}
.pr_0 {
  padding-right: 0;
}
.pb_0 {
  padding-bottom: 0;
}
.pt_0 {
  padding-top: 0;
}
.pt_0_imp {
  padding-top: 0 !important;
}
.pb_32 {
  padding-bottom: 3.2rem;
}

.p_auto {
  padding: 0 1.5rem;
}
// Heading Tags
h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .montserrat_regular;
  color: $black-color;
}
p {
  @extend .montserrat_regular;
  color: $black-color;
}

// Positions
.position_fixed {
  position: fixed;
}

.position_relative {
  position: relative;
}

.position_absolute {
  position: absolute;
}

// Opacity
.opacity_1{
  opacity: 1;
}

.opacity_0{
  opacity: 0;
}

// General
.border_0 {
  border: 0;
}

.border_bottom_1 {
  border-bottom: 0.1rem solid $separtor-color;
}

.cursor_pointer,
button {
  cursor: pointer;
}

// Text
.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.text_justify {
  text-align: justify;
}
.text_uppercase {
  text-transform: uppercase;
}

.text_lowercase {
  text-transform: lowercase;
}
.text_capitalize {
  text-transform: capitalize;
}
.text_decoration_none {
  text-decoration: none;
}
.over_hidden {
  overflow: hidden;
}
.hover_transition {
  transition: all 0.8s ease-in-out;
}
.tooltip {
  @include tooltip;
}

//error
.error_msg {
  input,
  textarea,
  .phone_number {
    border: 0.2rem solid $error-msg !important;
    margin-bottom: 0.4rem;
  }
}
.error_msg {
  .phone_number {
    input {
      border: none !important;
    }
  }
}
.show_error_msg {
  color: $error-msg;
  font-size: 1.4rem;
  @extend .montserrat_medium;
}

.blue_text {
  margin-left: 1.2rem;
  color: $btn_primary;
  @extend .fw_700;
  font-size: $fs14;
  margin: auto;
}

.status_active {
  background-color: $success-color;
}
.status_pending {
  background-color: $grey-color;
}

.msg_border_icon {
  width: 4rem;
  height: 4rem;
  border: 0.1rem solid $btn_disable;
  border-radius: 5rem;
  display: grid;
  place-items: center;
  background-color: $white-color;
  @include trans;
  img {
    width: 3rem;
    height: 3rem;
  }
  &:hover {
    border-color: $primary-color;
  }
}
.custom-scroll {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: $form-input-active-bg;
    border-radius: 10rem;
  }
  &::-webkit-scrollbar-thumb {
    background: $scroll-color;
    border-radius: 10rem;
  }
}
.custom_box_shadow {
  box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);
  -webkit-box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);
}

.form-label {
  font-size: $fs14;
  color: $gray-two;
  @extend .d_block;
  margin-bottom: 0.8rem;
  @extend .montserrat_bold;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  color: $gray-color;
  background: none;
}

.custom-modal {
  padding: 0;
  border-radius: 1.5rem;
}

.loader_bg {
  @extend .w_100;
  @extend .h_100;
  @extend .position_absolute;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
}

.loader {
  @extend .position_absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  @extend .text_center;
  @extend .d_flex_center;

  img {
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.unauthoriz_page {
  @extend .position_relative;
  &::after {
    content: "";
    @extend .d_block;
    @extend .w_100;
    height: 54rem;
    max-width: 54rem;
    background: url(../images/unauthoraiz_bg.png) no-repeat;
    @extend .position_absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .unautho_content {
    @extend .w_100;
    height: 100vh;
    max-width: 67rem;
    @extend .m_auto;
    @extend .d_flex_center;
    @extend .flex_direction_column;
    h1 {
      color: $tab-head;
      font-size: 20.6rem;
      @extend .fw_500;
      @extend .d_flex_center;
      margin-bottom: 3.2rem;
      @include media_query(medium) {
        font-size: 10rem;
      }
      span {
        margin: 0 -2.5rem;
        @include media_query(medium) {
          max-width: 10rem;
          width: 100%;
        }
      }
    }
    h2 {
      color: $tab-head;
      font-size: $fs48;
      @extend .fw_700;
      line-height: normal;
      max-width: 66.7rem;
      @extend .m_auto;
      @extend .text_center;
      margin-bottom: 1.6rem;
      @include media_query(medium) {
        font-size: $fs24;
      }
    }
    .content {
      color: #8f8f8f;
      @extend .fs_24;
      @extend .fw_400;
      @extend .text_center;
      line-height: 3.6rem;
      margin-bottom: 5.6rem;
      @include media_query(medium) {
        font-size: $fs16;
      }
    }
    .unauth_btn {
      .primary_btn {
        padding: 2rem 7rem;
        min-width: 29.1rem;
        min-height: 6.8rem;
      }
    }
  }
}

.border_red {
  border: 0.2rem solid $red-required;
}

.border_green {
  border: 0.2rem solid $form_bdr;
}
.border_blue {
  border: 0.2rem solid $blue-color;
}
.text_span_ellipse {
  span {
    width: 4.5rem;
    @extend .d_block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.footer_text {
  font-size: 1.3rem;
  padding-bottom: 0;
  padding-left: 7.9rem;
  @extend .position_absolute;
  top: 50%;
  transform: translateY(-50%);
  @include media_query(two_extra_large) {
    padding-left: 5rem;
  }
  @include media_query(extra_large) {
    padding-left: 4rem;
  }
  @include media_query(large) {
    padding-left: 3rem;
  }
  @include media_query(medium) {
    position: unset;
    padding-left: 0;
    order: 2;
    transform: unset;
  }
  a {
    color: $btn_primary;
  }
}

.box_border {
  border-radius: 0.8rem;
  border: 1px solid $btn-disable;
  background-color: $white-color;
}

.auto-complete {
  &_input {
    @extend .position_relative;
    &_add {
      &_icon {
        @extend .position_absolute;
        padding: 0;
        right: 0.5rem;
        top: 0.4rem;
        button {
          padding: 0.8rem;
        }
      }
    }
  }
  &_options {
    background: $white-color;
    border: 0.1rem solid $separtor-color;
    border-radius: 0;
    border-radius: initial;
    box-shadow: 0 0 30px 0 hsla(0, 0%, 45%, 0.15);
    max-height: 15.5rem;
    overflow-y: auto;
    z-index: 99;
    @extend .position_absolute;
    @extend .w_100;
    overflow-x: hidden;
    border-radius: 0.4rem;
    &_option {
      @extend .d_flex;
      padding: 1.2rem;
      @extend .align_items_center;
      @extend .cursor_pointer;
      svg {
        margin-right: 1.5rem;
      }
      span {
        color: $gray-two;
        font-size: $fs16;
        font-weight: $fw500;
        line-height: 140%;
      }
      &:hover {
        background: $table-hover;
      }
    }
  }
  .more_school_list {
    max-height: 14.5rem;
    overflow: auto;
    padding: 0.5rem;
    .add_school_list {
      @extend .d_flex_center;
      @extend .justify_content_start;
      @extend .flex_wrap;
      gap: 0.8rem;
      .add_school_inst_name {
        @extend .fs_16;
        @extend .fw_500;
        line-height: 140%;
        color: $black-color;
        padding: 0.8rem 1.6rem;
        @extend .radius_30;
        background: $grey-color;
        margin-bottom: 0.8rem;
        @extend .d_flex_center;
        gap: 0.8rem;

        svg {
          @extend .cursor_pointer;
          height: 1.5rem;
          width: 1.6rem;
          background: $primary-color;
          border-radius: 50%;
          @extend .d_flex_center;
          g {
            path {
              stroke: $white-color;
            }
          }
        }
      }
    }
  }
}

.select-options {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: $white-color;
  max-width: min-content;
  .select-item-list {
    .select-item {
      &:focus {
        background: $table-hover;
      }
    }
    .select-item[data-state="checked"] {
      background: $table-hover;
      @extend .montserrat_bold;
    }
  }
}
.backdrop-overlay {
  background: rgba(22, 35, 83, 0.3);
}
.checkbox-block {
  button {
    height: 2.4rem;
    @extend .w_100;
    max-width: 2.4rem;
    margin-right: 1rem;
    border: 0.2rem solid $gray-two;
    border-radius: 0.5rem;
  }
  [data-state="checked"] {
    background-color: $btn_primary;
    height: 2.4rem;
    border: none;
    border-radius: 0.5rem;
  }

  margin-bottom: 1.6rem;
  &:last-child {
    margin-bottom: 0;
  }

  label {
    color: $gray-two;
    @extend .montserrat_regular;
    font-size: $fs16;
    &.select_text {
      @extend .montserrat_bold;
    }
  }
}

.select_checkbox_space {
  .cust-z-index {
    z-index: 11;
  }
  .rotate_dropdownIndicator {
    svg {
      transform: rotate(180deg);
    }
  }
  svg {
    @extend .cursor_pointer;
  }
  .css-13cymwt-control {
    min-height: 4.8rem;
    .css-1hb7zxy-IndicatorsContainer {
      &:focus-visible {
        outline: transparent;
      }
    }
  }
  .css-t3ipsp-control {
    min-height: 4.8rem;
    padding-right: 1.6rem;
    &:focus-visible {
      outline: transparent;
    }
  }
}

.mobile-hide {
  @include media_query(large) {
    display: none !important;
  }
}
.slot_popup {
  max-width: 47.2rem;
  @extend .position_absolute;
  left: 0;
  right: 0;
  @extend .m_auto;
  bottom: 12rem;
  @include media_query(two_fifth_extra_large) {
    right: 8.5rem;
  }
  @include media_query(large) {
    right: 0;
  }
  .slot_wrapper {
    @extend .position_relative;
    padding: 2.4rem 3.2rem;
    background-color: $white-color;
    border-radius: 0.8rem;
    box-shadow: 0px 8px 21px 0px rgba(30, 45, 102, 0.08);
    margin-bottom: 1rem;
    @include media_query(small) {
      margin-bottom: 6rem;
    }
    .close_btn {
      @extend .position_absolute;
      right: 2rem;
      top: 2rem;
    }
    .slot_box {
      @extend .d_flex_between;
      gap: 2.4rem;
      flex-wrap: wrap;
      span {
        width: 8rem;
        height: 8rem;
        background-color: $form-input-active-bg;
        @extend .radius-50;
        display: grid;
        place-items: center;
      }
      .slot_info {
        width: calc(100% - 10.4rem);
        h5 {
          @extend .montserrat_semibold;
          margin-bottom: 1.2rem;
        }
        p {
          font-size: $fs16;
        }
      }
    }
  }
  @include media_query(medium) {
    max-width: 92%;
    h5 {
      font-size: $fs20;
    }
  }
  @include media_query(small) {
    max-width: 85%;
    bottom: 10rem;
    .slot_wrapper {
      .slot_box {
        flex-direction: column;
        justify-content: center;
        .slot_info {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

.fake-placeholder {
  @extend .position_absolute;
  top: 3rem;
  left: 1.3rem;
  color: $form-input-color;
  pointer-events: none;
  transition: opacity 0.3s;
  opacity: 1;
  background: $white-color;
  line-height: 140%;
  font-size: $fs16;
  height: 3rem;
  padding: 0.5rem 0.6rem 0 0.6rem;
}

input[type="time"]:focus + .fake-placeholder {
  @extend .d_none;
}

// input[type="search"]::-webkit-search-cancel-button {
//   appearance: none;
//   background: url("../images/search_close.png") no-repeat center;
//   object-fit: contain;
//   width: 1.6rem;
//   height: 1.6rem;
//   @extend .cursor_pointer;
// }

.typing-indicator {
  width: auto;
  @extend .d_flex_center;
  @extend .m_auto;
  @extend .w_100;
  // animation: 2s bulge infinite ease-out;
  span {
    height: 1.5rem;
    width: 1.5rem;
    float: left;
    margin: 0 0.1rem;
    background-color: #9e9ea1;
    @extend .d_block;
    border-radius: 50%;
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * 0.3333s);
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

.react_dropdown {
  .css-13cymwt-control {
    .css-k59dtu-singleValue {
      margin: 0;
    }
  }
}
.react-input-select {
  input {
    min-height: unset !important;
  }
}

.input_track {
  height: 0.5rem;
  @extend .w_100;
  border-radius: 0.4rem;
  align-self: center;
  > div {
    cursor: pointer !important;
  }
}
.input_dot_drag {
  width: 2.1rem;
  height: 2.1rem;
  border: 0.1rem solid $primary-color;
  background-color: $white-color;
  border-radius: 10rem;
  @extend .cursor_pointer;
}
