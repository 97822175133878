.i_popup {
  @extend .position_fixed; 
    z-index: 100; 
    padding: 1.0rem;
    left: 0;
    top: 0;
    @extend .w_100;
    @extend .h_100;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); 
  .i_popup_content {
    border-radius: 2rem;
    border: 1px solid $btn_disable;
    background: $white-color;
    box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);
    padding: 3.2rem;
    max-width: 70rem;
    @extend .w_100;
    @extend .m_auto;
    @extend .position_fixed;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    .i_popup_header{
        .i_popup_title{
          @extend .montserrat_bold;
          @extend .fs_20;
          @include media_query(extra_small){
            font-size: $fs16 !important;
          }
        }
        .i_close{
          @extend .position_absolute;
          right: 2.5rem;
          top: 1.5rem;
        }
    }
    .main_picture_upload {
      .container-editPopup{
        @extend .position_relative;
        border-radius: 1.6rem;
        height: 34.1rem;
        @extend .m_auto;
        max-width: 34.1rem;
        @extend .over_hidden;
        .reactEasyCrop_Image{
          @extend .w_100;
        }
        .reactEasyCrop_CropAreaRound{
          height: 30.5rem!important;
          width: 30.5rem!important;
        }
      }
      .image_set_layer {
        gap: 3.1rem;
        padding-bottom: 4.8rem;
        padding-top: 4rem;
        span{
          font-size: $fs14;
          @extend .montserrat_bold;
        }
      }
      ul{
        gap: 1.5rem;
        li{
          @extend .list_unstyled;
          .blue_text{
            margin-left: 1.2rem;
          }
        }
      }
    }
    @include media_query(medium){
      max-width: 85%;
    }
  }
  .all_inner_content{
    .pop_details{
      span.google_meet_bg{
        border-radius: 50%;
        background: $form-input-active-bg;
        width: 6.5rem;
        height: 6.5rem;
        @extend .d_flex_center;
      }
      p{
        @extend .fw_500;
        font-size: $fs16;
      }
      .google_meet_inner {
        gap: 4.5rem;
        margin-top: 3rem;
      }
      .sub_data{
        h2{
          margin-top: 2rem;
        }
        p{
          @extend .fw_500;
          font-size: $fs20;
          color: $gray-two;
          @extend .text_center;
          margin-top: 3.2rem;
          margin-bottom: 3.2rem;
        }
        a{
          @extend .fw_400;
          font-size: $fs16;
          @extend .text_center;
          color: $btn_primary;
          @extend .d_block;
          margin-bottom: 3.2rem;
        }
      }
      .sub_data_btn{
        padding-top: 3.2rem;
        gap: 0.8rem;
        .upload_img{
          max-width: 20rem;
          border-color: $btn_primary;
          @extend .montserrat_bold;
          &.join_btn{
            background-color: $btn_primary;
            color: $white-color;
            @extend .border_0;
          }
          &.disable{
            background-color: $grey-text
          }
        }
      }
    }
  }
  &.i_match_into_pair{
    .i_popup_content{
        max-width: 70rem;
        @include media_query(medium){
          max-width: 48rem;
        }
        @include media_query(md_small){
          max-width: 40rem;
        }
        @include media_query(extra_small){
          padding: 1.5rem
        }
        .i_popup_header{
            &.popup_center_title {
                .i_popup_title{
                    @extend .fs_20;
                }
            }
        }
        .i_inner_content{
            background-color: $light-blue;
            padding: 2.1rem 0;
            border-radius: 0.8rem;
            @extend .d_flex_center;
            @extend .position_relative;
            margin-top: 3.2rem;
            @include media_query(extra_small){
              padding: 1.5rem 0 1.5rem;
            }
            &::after{
                content: '';
                @extend .position_absolute;
                background: url(../images/match_pair_bg.svg);
                background-repeat: no-repeat;
                width: 12.9rem;
                height: 13.0rem;
                bottom: 0;
                right: -3.4rem;
                @include media_query(medium){
                  content: none; 
                }
              }
            &::before{
                content: '';
                @extend .position_absolute;
                background: url(../images/match_pair2.svg);
                background-repeat: no-repeat;
                width: 12.9rem;
                height: 13.0rem;
                bottom: 1.0rem;
                left: 0;
                @include media_query(medium){
                  content: none; 
                }
            }
            .matches_user{
                @extend .text_center;
                width: 16.5rem;
                h6{
                    @extend .montserrat_bold;
                    @extend .fs_14;
                    line-height: 140%; /* 19.6px */
                    text-overflow: ellipsis;
                    max-width: 12rem;
                    @extend .w_100;
                    white-space: nowrap;
                    @extend .over_hidden;
                    @extend .m_auto;
                }
                .profile_img{
                    border-radius: 10.0rem;
                    border: 0.3rem solid $white-color;
                    width: 8.8rem;
                    height: 8.8rem;
                    @extend .over_hidden;
                    @extend .m_auto;
                    box-shadow: 5px 5px 24px 0px rgba(134, 172, 218, 0.44);
                    @include media_query(md_small){
                      width: 7.5rem;
                      height: 7.5rem;
                    }
                    @include media_query(extra_small){
                      width: 5rem;
                      height: 5rem;
                    }
                    img{
                        object-fit: cover;
                        @extend .w_100;
                        @extend .h_100;
                    }
                }
                .tag_name{
                    border-radius: 5.0rem;
                    border: 0.1rem solid $white-color;
                    background: $tag-blue;
                    @extend .d_flex_center;
                    gap: 0.7rem;
                    padding: 0.5rem 1.1rem;
                    max-width: 9.2rem;
                    @extend .w_100;
                    margin:-1.0rem auto 0;
                    @extend .position_relative;
                    img{
                        width: 1.7rem;
                    }
                    p{
                        color: $primary-color;
                        font-size: 1.0rem;
                        @extend .montserrat_bold;
                    }
                }
            }
            .s_checkmark{
                @extend .position_relative;
                z-index: 1;
                &::after{
                    content: '';
                    @extend .position_absolute;
                    background: url(../images/Line1.svg);
                    background-repeat: no-repeat;
                    width: 9.0rem;
                    height: 0.6rem;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    -webkit-transform: translate(-50%,-50%);
                    z-index: -1;
                }
            }
            
        }
        .imentor_info {
            padding: 3.2rem 0;
        }
        .i_popup_footer{
            @extend .p_0;
            .btn_request_info {
                max-width: 20rem;
            }
            .btn_approve{
                width: auto;
                max-width: unset;
            }
            .btn_reject{
                max-width: 20rem;
                img{
                    filter: brightness(10);
                }
            }
            form{
              gap: 0.8rem;
              margin-top: 3.2rem;
              .btn_request_info{
                min-width: 20rem;
                color: $gray-color;
                &:hover{
                  color: $white-color;
                }
              }
              .btn_outline{
                max-height: 4.8rem;
                @extend .d_flex_center;
                padding: 1.2rem 2.4rem;
                white-space: nowrap;
                @include media_query(medium){
                  max-width: 15.7rem;
                }
              }
              .btn_approve{
                min-width: 24.5rem;
                gap: 1.2rem;
                @extend .justify_content_center;
                max-height: 4.8rem;
                @include media_query(medium){
                  min-width: 17.5rem;
                }
              }
            }
        }
    }
  }
  &.i_choose_image_popup{
    
    .i_popup_content {
      min-height: unset;
      max-height: 38rem;
      min-height: 38rem;
      max-width: 56.4rem;
      padding: 0 0 2rem;
      width: calc(100% - 30px);
      @extend .d_flex;
      @extend .flex_direction_column;
      .inner_contect::after{
        content: none;
      }
      .i_popup_header{
        padding: 1.6rem 3.2rem 1.7rem;
        .i_popup_title{
          @extend .w_100;
          margin: auto;
          line-height: 4rem;
          @extend .fs_16;
          @extend .montserrat_bold;
          @extend .justify_content_center;
        }
      }
      .inner_contect{
        flex: 1;
        overflow: auto;
        @extend .position_relative;
        .library_img_list{
          padding: 3.2rem 3.3rem 0rem;
          @include media_query(small){
            padding: 0rem 2rem;
          } 
          ul{
            @extend .d_flex;
            @extend .flex_wrap;
            li{
              padding: 1rem 1.6rem;
              width: 25%;
              @include media_query(small){
                padding: 1rem;
              }
              .lib_img_main{
                width: 100%;
                border-radius: 0.6rem;
                padding-bottom: 100%;
                @extend .position_relative;
                @extend .over_hidden;
                @extend .cursor_pointer;
                @include media_query(small){
                  border-radius: 0.4rem;
                }
                img{
                  @extend .w_100;
                  @extend .h_100;
                  @extend .img_object_center;
                  @extend .position_absolute;
                  left: 0;
                  top: 0;
                }
              }
            }
          }
          .img_lib_loader{
            @extend .position_absolute;
            width: 24px;
            margin: auto;
            inset: 0;
            height: 24px;
            svg{
              margin: 0;
            }
          }
        }
      }
    }
  }
}