.container {
  padding: 0 2.5rem;
  margin: 0 auto;
}

.wrapper {
  @extend .position_relative;
}

.btn {
  font-size: 1.6rem;
  line-height: 2.4rem;
  @extend .montserrat_bold;
  border-radius: 0.8rem;
  color: $white-color;
  padding: 0.8rem 2.4rem;
  background-color: $btn_primary;
  @extend .d_flex_center;
  @extend .align_items_center;
  @include trans;
  @extend .position_relative;
  overflow: hidden;
  z-index: 0;
  &::after {
    content: "";
    @extend .position_absolute;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    top: 0;
    right: -100%;
    opacity: 0;
    @include trans;
    z-index: -1;
  }
  &:hover {
    &:after {
      right: 0;
      opacity: 1;
    }
  }
}

.common_header {
  padding: 0 3.4rem;
  border-bottom: 1px solid $btn_disable;
  @include media_query(large) {
    padding: 0 2rem;
  }
  @include media_query(small) {
    background-color: $primary-color;
    padding: 0 2.5rem;
    position: fixed;
    z-index: 100;
    width: 100%;
  }
  
  .common_header_wrapper {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    @include media_query(medium) {
      row-gap: 2rem;
      justify-content: space-between;
      flex-wrap: inherit;
      @include trans;
    }
    @include media_query(small) {
      justify-content: space-between;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }
  }
  .left {
    @include media_query(medium) {
      width: auto;
      @include trans;
    }
    .back_home {
      column-gap: 2rem;
      @include media_query(large) {
        column-gap: 1rem;
      }
      @include media_query(medium) {
        @include trans;
      }
      @include media_query(small) {
        justify-content: start;
      }
      h5 {
        @extend .fw_500;
        font-size: 1.8rem;
        @include media_query(large) {
          font-size: 1.6rem;
        }
        @include media_query(medium) {
          white-space: nowrap;
        }
        span {
          @extend .montserrat_bold;
        }
      }
      .back_home_wrapper {
        column-gap: 2rem;
        @include media_query(medium) {
          column-gap: 1rem;
        }
        @include media_query(small) {
          display: none;
        }
      }
      .mobile_logo {
        @extend .d_none;
        @include trans;
        @include media_query(small) {
          display: block;
          @include trans;
        }
      }
    }
    .admin-title {
      position: relative;
      padding-left: 2rem;
      @include media_query(small){
        display: none;
      }
      h5 {
        font-size: 2rem;
        @extend .montserrat_semibold;
        line-height: 2.8rem;
        @include media_query(small) {
          color: $white-color;
        }
      }
      &:after {
        position: absolute;
        content: "";
        width: 1rem;
        height: 1rem;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $btn_primary;
        border-radius: 5rem;
      }
    }
  }
  .right {
    column-gap: 1.6rem;
    @include media_query(two_extra_large) {
      padding-right: 0;
      column-gap: 1rem;
      margin-left: 1.5rem;
    }
    @include media_query(small) {
      column-gap: 1rem;
    }
    .common_right_head {
      appearance: none;
      width: 4.2rem;
      height: 4.2rem;
      border-radius: 10rem;
      border: 1px solid $btn_disable;
      background: $white-color;
      @extend .cursor_pointer;
      @extend .d_flex_center;
      padding: 0.8rem;
      @include trans;
      @include media_query(medium) {
        padding: 0.5rem;
      }
      @include media_query(small) {
        padding: 0.5rem;
        width: 4rem;
        height: 4rem;
      }
      &.search_head {
        @include media_query(small) {
          display: none;
        }
      }
      &:hover {
        background-color: $primary-color;
        border: transparent;
        img {
          filter: brightness(10);
        }
      }
      &.notification {
        @extend .position_relative;
        &::before {
          @extend .position_absolute;
          content: "";
          width: 0.8rem;
          height: 0.8rem;
          background-color: $btn_primary;
          @extend .radius-50;
          top: 0;
          right: 0.2rem;
        }
      }
    }
    .profile_info {
      margin: 0 0.8rem;
      column-gap: 1.6rem;
      appearance: none;
      @include media_query(medium) {
        margin: 0;
        column-gap: 1rem;
      }
    }
    .profle_img {
      @extend .w_100;
      max-width: 4rem;
      height: 4rem;
      border-radius: 4rem;
      overflow: hidden;
      @include media_query(medium){
        width: 4rem;
        max-width: 100%;
      }
      img {
        width: 4rem;
        height: 4rem;
        object-fit: cover;
        @include media_query(medium){
          width: 100%;
          height: 100%
        }
      }
    }
    .user_name {
      @extend .fw_600;
      @extend .w_100;
      min-width: 16.5rem;
      font-size: 1.6rem;
      color: $primary-color;
      
      @include media_query(medium) {
        width: calc(100% - 5rem);
        font-size: $fs14;
      }
      img {
        margin-left: 0.8rem;
        display: inline-block;
      }

      span {
        color: $grey-text;
        font-size: 1.2rem;
        @extend .d_block;
        @extend .montserrat_medium;
      }
      @include media_query(small) {
        display: none;
      }
    }
  }
  &.sticky_comman_header {
    position: fixed;
    top: 0;
    background: $white-color;
    width: calc(100% - 214px);
    z-index: 9;
    @include media_query(large) {
      width: calc(100% - 160px);
    }
    @include media_query(small) {
      width: 100%;
      background-color: $primary-color;
    }
  }
}
// .admin_user_page{
//   &.cust_cls{
//       @include media_query(small){
//         margin-top: 5.5rem;
//       }
//     }
//   }
.add_mentor_footer {
  background: #fff;
  border-top: 0.1rem solid #e0e0e0;
  border-top: 0.1rem solid var(--grey-colors-grey-5, #e0e0e0);
  box-shadow: -0.8rem -0.8rem 2.5rem 0 rgba(30, 45, 102, 0.03);
  padding: 1.6rem 3.2rem;
  padding-top: 3.2rem;
  position: absolute;
  bottom: 0;
  @include media_query(small) {
    // bottom: 8%;
    bottom: 0;
    padding: 2.5rem 3.4rem;
  }
  @extend .w_100;
  ul.footer_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    li {
      width: 23%;
      padding-bottom: 0;
      @include media_query(large) {
        width: 11%;
      }
      @include media_query(medium) {
        display: none;
      }
      p {
        @extend .fw_500;
        font-size: 1.3rem;
        span {
          @extend .montserrat_bold;
          color: $btn_primary;
          cursor: pointer;
        }
      }
      &:nth-child(1) {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      &:nth-child(2) {
        gap: 0.8rem;
        padding-bottom: 0;
        @include media_query(medium) {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        @include media_query(extra_small) {
          display: block;
        }
      }
      button {
        font-size: 1.35rem;
        line-height: 150%;
        @include media_query(extra_small) {
          margin: 0 auto 1rem;
        }
        &.upload_img {
          max-width: 17.6rem;
          min-width: 17.6rem;
          padding: 0.75rem 0;
          @include media_query(small) {
            min-width: none;
            max-width: 17.6rem;
          }
          &:hover {
            img {
              filter: brightness(21);
            }
          }
        }
        &.continue_btn {
          background-color: $btn_primary;
          color: $white-color;
          gap: 1rem;
          max-width: 17.6rem;
          min-width: 17.6rem;
          border: 0.2rem solid $btn_primary;
          border-radius: 0.8rem;
          padding: 1.2rem 2.4rem;
          justify-content: center;
          @include media_query(small) {
            min-width: none;
            max-width: 17.6rem;
          }

          img {
            vertical-align: top;
            margin: 0 0 0 0.8rem;
          }
        }
      }
    }
  }
}

.iMentor_web_sec {
  padding-top: 5rem;
  @include media_query(large) {
    padding-top: 3.5rem;
  }
  @include media_query(small) {
    padding-top: 2.5rem;
  }
}

.back_home_mobile {
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  @extend .d_none;
  padding: 0.7rem;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.5rem;
  @include media_query(small) {
    display: inline-block;
  }
}

.loader {
  @extend .position_absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  @extend .text_center;
  @extend .d_flex_center;
  img {
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pagination {
  padding: 0 3.2rem;
  margin-bottom: 3.2rem;
  @include media_query(small) {
    padding: 0 2.4rem;
  }
  .par_page {
    @extend .d_flex_center;
    @extend .justify_content_end;
    gap: 4rem;
    width: 100%;
    background: $white-color;
    box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
    border-radius: 0.8rem;
    border: 1px solid $btn_disable;
    // border-top: 0;
    min-height: 5.6rem;
    // width: 100%;
    // min-width: 110rem;
    white-space: nowrap;
    padding: 0.8rem 6.4rem;
    .row_par_page {
      @extend .d_flex_center;
      gap: 16px;
      @include media_query(medium) {
        gap: 1.2rem;
      }
      h4 {
        @extend .fs_16;
        @extend .fw_500;
        line-height: 140%;
        color: $black-color;
        @include media_query(medium) {
          font-size: 1.4rem;
        }
      }
      .form_control {
        @extend .radius_4;
        padding: 0.8rem 1.6rem;
        border: 0.1rem solid #b9bed0;
        appearance: none;
        outline: none;
        background: url(../images/down_arrow.svg) no-repeat center right#fff;
        background-size: auto;
        width: 100%;
        max-width: 15.2rem;
        cursor: pointer;
      }
      .basic-single {
        min-width: 15.2rem;
        max-width: 15.2rem;
        border-radius: 4px;
        // border: 1px solid #1168d0;
        background: $white-color;
        box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
        outline: none;
        @include media_query(large) {
          min-width: 11.2rem;
          max-width: 11.2rem;
        }
        @include media_query(medium) {
          min-width: 8rem;
          max-width: 8rem;
        }
        .page_box {
          -webkit-box-align: center;
          align-items: center;
          cursor: default;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          min-height: 38px;
          position: relative;
          transition: all 100ms ease 0s;
          background-color: rgb(255, 255, 255);
          border-color: rgb(204, 204, 204);
          border-radius: 4px;
          border-style: solid;
          border-width: 1px;
          box-sizing: border-box;
          outline: 0px !important;
          input {
            border: 0;
            width: 100%;
          }
          .page_number {
            font-size: $fs16;
            padding-left: 1rem;
            @extend .montserrat_regular;
          }
        }
      }
    }
    .count_page {
      margin-bottom: 0;
      p {
        @extend .fs_16;
        line-height: 140%;
        @extend .fw_500;
        @extend .text_center;
        color: $black-color;
        @include media_query(medium) {
          font-size: 1.4rem;
        }
      }
    }
    .left_right_arrow {
      @extend .d_flex_center;
      gap: 3.2rem;
      @include media_query(extra_large) {
        gap: 2rem;
      }
      @include media_query(large) {
        gap: 1rem;
      }
      @include media_query(medium) {
        gap: 1.2rem;
      }
      .click_arrow {
        @extend .d_block;
        @extend .cursor_pointer;
        @extend .position_relative;
        border-radius: 100%;
        border: 0.2rem solid transparent;
        padding: 0.6rem;
        &:hover{
          border: 0.2rem solid $btn_primary;
          .tooltip {
            @extend .d_block;
            opacity: 1;
            top: -5.5rem;
            left: -3rem;
          }
        }
        &.left_page {
          img {
            transform: rotate(180deg);
          }
        }
        &.right_list {
          @extend .position_relative;
          border: 0.2rem solid transparent;
          border-radius: 100%;
          @extend .d_flex_center;
          padding: 0.6rem;
          &:hover {
            border: 0.2rem solid $btn_primary;
            padding: 0.6rem;
            .tooltip {
              @extend .d_block;
              opacity: 1;
              top: -5.5rem;
              left: -3rem;
            }
          }
          img {
            transform: rotate(180deg);
            &.pre_Img {
              transform: unset;
            }
          }

          &.active {
            border: 0.2rem solid $btn_primary;
          }
        }
        &.disable {
          pointer-events: none;
          img {
            opacity: 0.4;
          }
        }
      }
    }
    @include media_query(large) {
      padding: 0.8rem 1rem;
      gap: 2rem;
    }
    @include media_query(medium) {
      gap: 1.2rem;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  @include media_query(extra_small) {
    padding: 0 1.5rem;
  }
}
.comman_head {
  padding: 2.5rem 3.2rem 2.2rem;
  h3 {
    @extend .montserrat_medium;
    font-size: 2rem;
    line-height: 2.4rem;
    color: $black-color;
    &.textimg {
      img {
        @extend .mr_10;
      }
      font-size: 1.8rem;
    }
  }
  .comman_menu {
    gap: 1.6rem;
    @extend .w_100;
  }
  @include media_query(extra_small) {
    padding: 2.5rem 1.5rem 2.2rem;
  }
  &.content_fixed_header{
    @include media_query(small){
      position: fixed;
      width: 100%;
      background-color: $white-color;
      z-index: 1;
      top: 5.5rem;
    }
  }
}
.hr {
  margin: 4.8rem 0;
  border-bottom: 1px solid $hr-color;
}
.small_hr {
  margin: 3.2rem 0;
  border-bottom: 1px solid $hr-color;
}

.toaster_msg {
  @extend .position_absolute;
  top: auto;
  right: 10rem;
  bottom: 7rem;
  border-radius: 8px;
  border: 2px solid $form_bdr;
  background: $success-bg;
  padding: 1.6rem 2.4rem;
  @include media_query(medium) {
    right: 0;
    left: 0;
    max-width: 45rem;
    margin: 0 auto;
    bottom: 2rem;
    padding: 1rem;
  }
  @include media_query(small) {
    padding: 1rem 2.5rem;
    max-width: 35rem;
  }
  @include media_query(extra_small) {
    padding: 1rem 2.5rem;
    max-width: 30rem;
  }
  .toaster_wrapper {
    gap: 0.8rem;
    .mesg {
      @extend .montserrat_regular;
      font-size: 1.6rem;
      font-weight: $fw400;
      @include media_query(small) {
        font-size: 1.4rem;
      }
    }
  }
}

.toster_waper {
  @extend .position_fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 999;

  .toster {
    @extend .radius_8;
    border: 0.2rem solid $form_bdr;
    background: $success-bg;
    padding: 0.8rem 2.4rem;
    min-height: 6.6rem;
    @extend .d_flex_align;
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    .msgwrapper {
      @extend .w_100;
      gap: 3.2rem;
      .msg-text {
        gap: 0.8rem;
        p {
          @extend .fs_16;
          @extend .fw_400;
          line-height: 140%;
          color: $black_color;
        }
      }

      .undo_close {
        .undobtn {
          @extend .fs_16;
          @extend .montserrat_bold;
          line-height: 150%;
          color: $btn_primary;
          margin-right: 3.2rem;
        }
      }
    }

    &.Danger {
      border: 0.2rem solid $red-required;
      background-color: #fffafa;

      .msgwrapper {
        @extend .w_100;
        @extend .align_items_center;

        .msg-text {
          svg {
            path {
              fill: $red-required;
            }
          }
        }
      }
    }
  }
}

.remove_tooltip {
  @extend .position_relative;
  &:hover {
    .tooltip {
      @extend .d_block;
      opacity: 1;
      left: -7rem;
    }
  }
}
.back_home_wrapper {
  @include media_query(small) {
    h5 {
      font-family: "Montserrat_medium";
      font-weight: 500;
      font-size: 1.8rem;
      color: $black-color;
      margin-left: 2rem;
      span {
        font-family: "Montserrat_bold";
        font-weight: 700;
      }
    }
  }
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: $form-input-active-bg;
  border-radius: 10rem;
}
::-webkit-scrollbar-thumb {
  background: $scroll-color;
  border-radius: 10rem;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  cursor: pointer;
}