.table_wrapper {
  padding: 0 3.2rem;
  @include media_query(small) {
    padding: 0 2.4rem;
  }
  .table_responsive {
    overflow: auto;
    width: 100%;
    border-radius: 0.8rem;
    border: 1px solid $btn_disable;
    @extend .custom-scroll;
    &.progress_table_layout {
      @include media_query(medium) {
        border: 0;
      }
    }
    .table {
      border-collapse: initial;
      border-spacing: 0;
      //   min-width: 10rem;
      position: relative;
      //   table-layout: fixed;
      width: 100%;
      background-color: $white-color;
      &.more_page {
        @include media_query(small) {
          border-collapse: collapse;
          border-spacing: unset;
        }
        tr {
          td {
            &:first-child {
              padding-left: 2.2rem;
              padding-right: 0;
              width: 56px;
              width: 0;
              display: none !important;
              @include media_query(extra_small) {
                padding-right: 0;
                padding-left: 1rem;
                width: 38px;
              }
            }
            &:nth-child(2) {
              width: 250px;
              @include media_query(small) {
                width: 25rem;
              }
              @include media_query(extra_small) {
                width: 21rem;
                padding-left: 1.2rem;
              }
            }
            &:nth-child(3) {
              width: 150px;
            }
            &:nth-child(4) {
              width: 230px;
              .user_name {
                max-width: 100%;
              }
            }
            &:nth-child(5) {
              width: 153px;
            }
            &:nth-child(6) {
              width: 153px;
            }
            &:last-child {
              width: auto;
              padding-right: 1rem;
              position: relative;
              @extend .position_relative;
              .table_skeleton {
                position: absolute;
                width: 40px;
                right: 2.4rem;
                top: 50%;
                transform: translateY(-50%);
              }
              .dot_icon_modal {
                @extend .position_absolute;
                right: 2.4rem;
                top: 50%;
                transform: translateY(-50%);
                @include media_query(small) {
                  transform: unset;
                  position: unset;
                }
              }
              .option_icon {
                img {
                  display: none;
                }
              }
              @include media_query(small) {
                position: absolute;
                right: 0rem;
                width: 5.6rem;
              }
              @include media_query(extra_small) {
                padding-right: 0;
                width: 5rem;
              }
            }
            .name_toolkit {
              @extend .position_relative;
              @include trans;
              @extend .d_flex;
              .user_name {
                cursor: pointer;
                line-height: 2.1rem;
                max-width: 18.2rem;
                min-height: 0px;
                text-overflow: ellipsis;
                // white-space-collapse: collapse;
                // text-wrap: nowrap;
                white-space: nowrap;
                width: auto;
                border-bottom: 1px solid transparent;
                overflow: hidden;
                @extend .montserrat_medium;
                font-size: 1.6rem;
                line-height: 140%;
                color: $gray-two;
                @extend .d_inline_block;
                &.green_bg_active {
                  background-color: $success-color;
                  padding: 0.5rem 1.2rem;
                  border-radius: 0.6rem;
                }
                &.invited_bg_clr {
                  background-color: $grey-color;
                  padding: 0.5rem 1.2rem;
                  border-radius: 0.6rem;
                }
                &.hyperLink:hover {
                  color: $secondary-color;
                  // line-height: 150%;
                  border-bottom: 1px solid $secondary-color;
                  @extend .montserrat_semibold;
                }
                @include media_query(extra_small) {
                  font-size: 1.4rem;
                }
              }
              .custom_tip {
                background: $primary-color;
                color: $white-color;
                cursor: pointer;
                @extend .d_none;
                @extend .position_absolute;
                z-index: 1;
                gap: 1rem;
                left: 4rem;
                line-height: 140%;
                padding: 0.8rem 1rem;
                top: -3.7rem;
                border-radius: 0.4rem;
                white-space: nowrap;
              }
              &:hover {
                .custom_tip {
                  @extend .d_flex;
                }
              }
            }

            @include media_query(small) {
              display: none;
              &:first-child,
              &:nth-child(2),
              &:last-child {
                display: inline-block;
                border: none;
                td {
                  padding: 1.9rem 0.8rem;
                }
              }
            }
          }
          &:hover {
            background-color: rgba(214, 226, 245, 0.2);
          }
          @include media_query(small) {
            border-bottom: 1px solid $btn-disable;
          }
        }
        tbody {
          tr {
            td {
              color: $gray-two;
              line-height: 2.2rem;
              border-top: 1px solid $btn_disable;
              padding: 2rem 2.4rem;
              @extend .montserrat_medium;
              font-size: 1.6rem;
              line-height: 140%;
              color: $gray-two;
              &:last-child {
                padding-inline: 1rem;
                .dot_icon_modal {
                  img {
                    @extend .m_auto;
                  }
                }
              }
              .dot_icon {
                height: 2.4rem;
                .option_menu {
                  margin-left: auto;
                  cursor: pointer;
                  display: inline-block;
                  &.active {
                    height: 4rem;
                    width: 4rem;
                    background: $white-color;
                    border-width: 0.2rem;
                    border-style: solid;
                    border-color: $primary-color;
                    border-image: initial;
                    display: flex;
                    border-radius: 5rem;
                  }
                  img {
                    max-width: fit-content;
                  }
                }
              }
              .dot_menu_modal {
                box-shadow: rgba(115, 115, 115, 0.15) 0px 0px 30px 0px;
                min-width: 18.3rem;
                padding-bottom: 0px;
                position: fixed;
                right: 4rem;
                width: auto;
                z-index: 99;
                background: $white-color;
                border-width: 0.1rem;
                border-style: solid;
                border-color: $btn_disable;
                border-image: initial;
                border-radius: 0.4rem;
                // display: none;
                .list_dot_menu {
                  align-items: center;
                  display: flex;
                  justify-content: flex-start;
                  padding: 1.6rem;
                  @include media_query(extra_small) {
                    padding: 1.2rem;
                    font-size: 1.4rem;
                  }
                  &:hover {
                    background-color: $form-input-active-bg;
                  }
                }
              }
              .user_pic {
                width: 2.4rem;
                margin-right: 1rem;
                @include media_query(small) {
                  width: 2.7rem;
                }
                img {
                  // @extend .w_100;
                  width: 2.5rem;
                  @include media_query(small) {
                    width: 2.7rem;
                  }
                }
              }
              .table_timezone {
                align-items: center;
                display: flex;
                .name_toolkit {
                  transition: all 0.8s ease-in-out;
                  padding-top: 0;
                  cursor: default;
                  position: relative;
                  &:hover {
                    .full_username {
                      display: flex;
                      align-items: center;
                    }
                  }
                  .status_tab.time_zone_label {
                    background: #f4f8fd;
                    margin-left: 8px;
                    line-height: normal;
                    color: #222222;
                    padding: 0.5rem 1.2rem;
                    min-height: 3rem;
                    vertical-align: middle;
                    display: inline-block;
                    border-radius: 0.6rem;
                    font-size: 1.6rem;
                    font-family: "Montserrat_regular";
                    font-weight: 400;
                  }
                  .full_username {
                    left: unset !important;
                    right: 50% !important;
                    transform: translate(50%, 0px);
                    line-height: 140%;
                    padding: 0.8rem 1rem;
                    background: #121b3d;
                    color: #ffffff;
                    gap: 1rem;
                    top: -3.7rem;
                    left: 0;
                    right: auto;
                    z-index: 9;
                    display: none;
                    white-space: nowrap;
                    border-radius: 0.4rem;
                    cursor: pointer;
                    position: absolute;
                    font-size: 1.4rem;
                    z-index: 9999 !important;
                  }
                }
              }
            }
            // &.selected {
            //   background-color: rgba(214, 226, 245, 0.2);
            // }
            &:last-child {
              border: 0;
            }
          }
        }
      }
      &.connection_table {
        tbody {
          tr {
            td {
              &:last-child {
                padding-right: 1.4rem;
              }
            }
          }
        }
      }
      &.progress {
        thead {
          tr {
            td {
              .thead {
                padding: 1.6rem 0.8rem 1.6rem 3.2rem;
                @include media_query(large) {
                  padding-left: 1.2rem;
                }
                @include media_query(medium) {
                  padding-inline: 2rem;
                }
              }
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 2rem 1.2rem 2rem 3.2rem;
              @include media_query(large) {
                padding-left: 1.2rem;
              }
            }
          }
        }
      }
      &.user_data_listing {
        @include media_query(extra_small) {
          thead {
            tr {
              td {
                &:nth-child(2) {
                  padding-left: 0;
                }
                .thead {
                  padding-left: 1.2rem;
                }
              }
            }
          }
          tr {
            td {
              &:last-child {
                @include media_query(small) {
                  right: 0.5rem;
                }
                @include media_query(extra_small) {
                  right: 0;
                }
              }
            }
          }
        }
      }
      .thead {
        color: $tab-head;
        line-height: 150%;
        padding: 1.6rem 0.8rem 1.6rem 2.4rem;
        @extend .montserrat_bold;
        white-space: nowrap;
        @extend .fs_16;
        @extend .d_flex;
        .down_arrow {
          display: grid;
          border: 1px solid transparent;
          border-radius: 10rem;
          height: 2.4rem;
          position: relative;
          place-items: center;
          width: 2.4rem;
          img {
            width: 1.6rem;
            filter: sepia(1);
          }
          &:hover {
            border-color: $btn_primary;
          }
          &.descending {
            img {
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
              transition: all 0.5s ease;
              -webkit-transition: all 0.5s ease;
            }
          }
        }
        @include media_query(extra_small) {
          font-size: 1.4rem;
        }
      }
    }
    &.mentees_table {
      .user_pic {
        width: 2.5rem;
        height: 2.5rem;
        img {
          @extend .w_100;
          @extend .h_100;
        }
      }
      @include media_query(small) {
        overflow-y: auto;
        height: 40rem;
      }
    }
    &.project_list {
      .table.more_page {
        .thead {
          padding: 2rem 2.4rem;
        }
        tr {
          td {
            &:first-child {
              padding-left: 2.2rem;
              padding-right: 0;
              width: 56px;
              width: 0;
              display: none !important;
              @include media_query(extra_small) {
                padding-right: 0;
                padding-left: 1rem;
                width: 38px;
              }
            }
            &:nth-child(2) {
              width: 40%;
              // width: 30%;
              @include media_query(small) {
                width: 25rem;
              }
              @include media_query(extra_small) {
                width: 21rem;
                padding-left: 1.2rem;
              }
            }
            &:nth-child(3) {
              width: 30%;
              // width: 20%;
            }
            &:nth-child(4) {
              width: 20%;
              position: static;
              .user_name {
                max-width: 100%;
              }
            }
            // &:nth-child(5) {
            //   width: 20%;
            // }
            &:last-child:not(.row) {
              padding-inline: 2.4rem;
              width: auto;
              padding-right: 1rem;
              position: relative;
              @extend .position_relative;
              .table_skeleton {
                position: absolute;
                width: 40px;
                right: 2.4rem;
                top: 50%;
                transform: translateY(-50%);
              }
              .dot_icon_modal {
                @extend .position_absolute;
                right: 2.4rem;
                top: 50%;
                transform: translateY(-50%);
                @include media_query(small) {
                  transform: unset;
                  position: unset;
                }
              }
              .option_icon {
                img {
                  display: none;
                }
              }
              @include media_query(small) {
                position: absolute;
                right: 0rem;
                width: 5.6rem;
              }
              @include media_query(extra_small) {
                padding-right: 0;
                width: 5rem;
              }
            }
          }
        }
      }
    }
  }
  @include media_query(extra_small) {
    padding: 0 1.5rem;
  }
}
.custom-checkbox {
  @extend .position_relative;
  display: flex;
  @extend .justify_content_start;
  .checked {
    & + span {
      @extend .position_relative;
      cursor: pointer;
      padding: 0;
    }
    & + span::before {
      content: "";
      display: inline-block;
      vertical-align: text-top;
      width: 16px;
      height: 16px;
      background-color: $white-color;
      border: 1px solid $gray-two;
      border-radius: 3px;
      margin-left: -13px;
      margin-top: 1px;
      @include trans;
    }
    @media (hover: hover) {
      &:hover + span::before {
        background-color: $primary-color;
        border-color: transparent;
      }
    }
    &:checked + span:before {
      background-color: $primary-color;
      border-color: transparent;
      @include trans;
    }
    & + span::after {
      content: "";
      position: absolute;
      top: -3px;
      left: -13px;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 4px;
      height: 10px;
      border: 1px solid $white-color;
      border-width: 0 2px 2px 0;
      transform: scale(0) rotate(45deg);
      @include trans;
    }
    &:checked + span::after {
      transform: scale(1) rotate(45deg);
    }
  }
}
