.form_small_container {
  max-width: 66.4rem;
  .form_wrapper {
    form {
      @extend .w_100;
      .form_steps {
        gap: 2.4rem;
        @include media_query(small){
          flex-wrap: wrap;
          display: block;
        }
      }
      .form_group {
        width: 48%;
        @extend .d_block;
        @include media_query(small){
          width: 100%;
        }
        label {
          @extend .montserrat_bold;
          font-size: $fs14;
          @extend .montserrat_bold;
          @extend .d_block;
          margin-bottom: 0.8rem;
          span {
            color: $red-required;
          }
        }
        .form_control {
          border: 0.1rem solid $separtor-color;
          color: $black-color;
          font-size: $fs16;
          font-weight: $fw400;
          padding: 0 1.6rem;
          &::placeholder {
            font-size: $fs16;
            font-weight: $fw400;
          }
          &type[date] {
            background: url(../images/calender.svg);
          }
          &:focus-visible {
            outline: 0;
          }
        }
        select {
          &#time_slot {
            background: url(../images/time_slot.svg) right 1.5rem center
              no-repeat;
              padding-right: 4.6rem;
            appearance: none;
            @include media_query(small){
              padding-right: 4.1rem;
            }
          }
        }
      }
    }
  }
}

.schedule_sec {
  @include media_query(small){
    margin-top: 12rem;
  }
  .form_wrapper {
    margin-top: 2.4rem;
  }
}