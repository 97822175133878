// font size
$fs12: 1.2rem;
$fs14: 1.4rem;
$fs16: 1.6rem;
$fs18: 1.8rem;
$fs20: 2rem;
$fs21: 2.1rem;
$fs24: 2.4rem;
$fs28: 2.8rem;
$fs32: 3.2rem;
$fs36: 3.6rem;
$fs40: 4rem;
$fs48: 4.8rem;
$fs56: 5.6rem;
$fs72: 7.2rem;
$fs90: 9rem;

// font-weight
$fw100: 100;
$fw400: 400;
$fw500: 500;
$fw600: 600;
$fw700: 700;
$fw800: 800;
$fw900: 900;

// montserrat font
@font-face {
  font-family: "Montserrat_thin";
  src: url("../fonts/Montserrat/Montserrat-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Montserrat_regular";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat_medium";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat_semibold";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat_bold";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat_extrabold";
  src: url("../fonts/Montserrat/Montserrat-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Montserrat_black";
  src: url("../fonts/Montserrat/Montserrat-Black.ttf");
  font-weight: 900;
}

// mercury display font
@font-face {
  font-family: "Mercury_Display_Regular";
  src: url("../fonts/Mercury-display/Mercury_Display_Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Mercury_Display_SemiBold";
  src: url("../fonts/Mercury-display/Mercury_Display_SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Mercury_Display_Bold";
  src: url("../fonts/Mercury-display/Mercury_Display_Bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Mercury_Display_Black";
  src: url("../fonts/Mercury-display/Magnel_Display_Black.ttf");
  font-weight: 900;
}
// inter font
@font-face {
  font-family: "Inter_bold";
  src: url("../fonts/Inter/Inter-Bold.ttf");
  font-weight: 700;
}

// star pattern font
@font-face {
  font-family: "fontello";
  src: url("../fonts/star/fontello.ttf");
  src: url("../fonts/star/fontello_1.ttf");
  src: url("../fonts/star/fontello_2.ttf");
  src: url("../fonts/star/fontello_3.ttf");
}

// font-family class
.montserrat_thin {
  font-family: "Montserrat_thin";
  font-weight: 300;
}
.montserrat_regular {
  font-family: "Montserrat_regular";
  font-weight: 400;
}
.montserrat_medium {
  font-family: "Montserrat_medium";
  font-weight: 500;
}
.montserrat_semibold {
  font-family: "Montserrat_semibold";
  font-weight: 600;
}
.montserrat_bold {
  font-family: "Montserrat_bold";
  font-weight: 700;
}
.montserrat_exbold {
  font-family: "Montserrat_extrabold";
  font-weight: 800;
}
.montserrat_black {
  font-family: "Montserrat_black";
  font-weight: 900;
}
.inter_bold {
  font-family: "Inter_bold";
  font-weight: 700;
}
.mercury_regular {
  font-family: "Mercury_Display_Regular";
  font-weight: 400;
}
.mercury_semibold {
  font-family: "Mercury_Display_SemiBold";
  font-weight: 600;
}
.mercury_bold {
  font-family: "Mercury_Display_Bold";
  font-weight: 700;
}
.mercury_black {
  font-family: "Mercury_Display_Black";
  font-weight: 900;
}