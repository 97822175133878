.iMentor_web_sec {
  @extend .w_100;
  @extend .h_100;
  &.custome_bg_img{
    @extend .over_hidden;
    &::before {
      content: "";
      background: url(../images/union_bg.png) no-repeat center;
      @extend .position_fixed;
      top: 40rem;
      width: 28.3rem;
      height: 29.9rem;
      left: 2rem;
      z-index: -1;
      @include media_query(medium){
        content: none;
      }
    }
    &::after {
      content: "";
      background: url(../images/union_bg.png) no-repeat center;
      @extend .position_fixed;
      top: 67%;
      width: 28.3rem;
      height: 29.9rem;
      right: -1rem;
      z-index: -1;
      @include media_query(medium){
        content: none;
      }
    }
  }
  .container {
    max-width: 93.6rem;
    .mob_spacing{
      @include media_query(small){
        padding-top: 12rem;
      }
    }
    h2 {
      @extend .montserrat_semibold;
      font-size: $fs32;
      @include media_query(small){
        font-size: 3rem;
      }
      @include media_query(extra_small){
        font-size: $fs24;
      }
    }
    p {
      @extend .montserrat_regular;
      font-size: $fs14;
      line-height: 1.96rem;
    }
    .prematch_step {
      margin-top: 10rem;
      padding-bottom: 10rem;
      @include media_query(medium) {
        margin-top: 5.5rem;
      }
      &.space_remove{
        margin-top: 2.4rem;
        .complete_onbording{
          @extend .d_flex;
          gap: 1.6rem;
          .custom-checkbox {
            padding-left: 0;
            @extend .d_flex;
            @extend .montserrat_regular;
            color: $gray-two;
            font-size: $fs16;
            .checked {
              & + span::before {
                margin-left: 0rem;
                border-width: 0.2rem;
                margin-top: -0.1rem;
                width: 2.4rem;
                height: 2.4rem;
              }
              &:hover + span::before {
                background-color: $btn_primary;
                border-color: $btn_primary;
              }
              &:checked + span:before {
                background-color: $btn_primary;
                border-color: $btn_primary;
              }
              & + span::after {
                top: -0.1rem;
                left: 0rem;
                width: 0.6rem;
                height: 1.5rem;
              }
            }
            input {
              @extend .d_none;
            }
            span {
              margin-right: 1.6rem;
              width: 2.4rem;
              height: 2.4rem;
              @include media_query(medium) {
                margin-right: 2rem;
              }
            }
          }
        }
        h6{
          font-size: $fs14;
          color: $gray-color;
          @extend .montserrat_bold;
        }
        .user_agreement{
          font-size: $fs14;
          @extend .montserrat_medium;
          color: $btn_primary;
          text-decoration: underline;
          padding: 0.8rem 0;
          @extend .d_inline_block;
          @extend .cursor_pointer;
        }
      }
    }
    > .prematch_wrapper{
      padding-bottom: 12rem;
      @include media_query(small){
        padding-top: 12rem;
      }
    }
  }
  ul {
    @extend .list_unstyled;
    @extend .position_relative;
    .absolute{
      @extend .position_absolute;
      top: 0;
      left: 0;
    }
    .step_info {
      gap: 1.5rem;
      .btn.continue_btn.d_flex_center{
        min-width: 17.6rem;
      }
      h4 {
        @extend .montserrat_semibold;
        font-size: $fs20;
        line-height: 2.438rem;
        span {
          @extend .montserrat_regular;
          font-size: $fs14;
          line-height: 2.8rem;
          color: $gray-three;
          @extend .d_block;
          &.active{
            color: $btn_primary;
            text-decoration: underline;
            @extend .cursor_pointer;
          }
        }
      }
      &.completed {
        .blue_icon {
          @extend .d_none;
        }
        .gray_icon {
          @extend .d_none;
        }
      }
      &.schedule {
        .blue_icon {
          @extend .d_block;
        }
        .gray_icon {
          @extend .d_none;
        }
        .complete_icon {
          @extend .d_none;
        }
      }
      &.pending {
        .blue_icon {
          @extend .d_none;
        }
        .gray_icon {
          @extend .d_block;
        }
        .complete_icon {
          @extend .d_none;
        }
      }
    }
    li {
      padding-bottom: 2.4rem;
    }
  }
  .pre_match_footer{
    background: $white-color;
    border-top: 0.1rem solid $btn_disable;
    border-top: 0.1rem solid var(--grey-colors-grey-5, $btn_disable);
    box-shadow: -0.8rem -0.8rem 2.5rem 0 rgba(30, 45, 102, 0.03);
    padding: 2.5rem 3.2rem;
    @extend .position_absolute;
    @extend .w_100;
    bottom: 0;
    .submit_btns{
      li{
        padding: 0;
        &:first-child{
          @extend .position_absolute;
          left: 0;
          @include media_query(large){
            display: none;
          }
        }
        .upload_img{
          min-width: 17.6rem;
          white-space: nowrap;
          font-size: 1.3rem;
          padding: 0;
          &:hover{
            > img{
              filter: brightness(21);
              @include trans;
            }
          }
        }
        p{
          a{
            color: $btn_primary;
            @extend .montserrat_bold;
            @extend .cursor_pointer;
          }
        }
        .continue_btn {
          min-width: 17.6rem;
          font-size: 1.3rem;
          @extend .justify_content_center;
          gap: 1rem;
          &:hover{
            color: $white-color;
          }
        }
      }
    }
  }
}

.congrats_screen{
  h2{
    @extend .montserrat_semibold;
    font-size: 3.2rem;
    line-height: 110%;
    margin-bottom: 1rem;
    @include media_query(extra_small){
      font-size: 2.6rem;
    }
  }
  p{
    @extend .montserrat_regular;
    font-size: 1.4rem;
    line-height: 140%;
  }
  .congratulations {
    margin-top: 5rem;
    @include media_query(small){
      margin-top: 0;
      padding: 15rem 0 0;
      h2{
        font-size: 2.5rem;
      }
    }
  }
}

// schedual popup
.schedule_sec_popup{
  .i_popup {
    .i_popup_content{
      padding: 3.2rem 0;
      .i_popup_title {
        @include media_query(small){
          text-align: left;
          padding-left: 3.2rem;
        }
      }
      .college_txt {
        padding: 2.8rem 0;
        margin-top: 2.2rem;
      }
    }
    .upload_img{
      @extend .white_space_nowrap;
    }
  }
  .pop_details{
    padding: 3.2rem 3.2rem 0 3.2rem;
    .sub_data {
      h4{
        @include media_query(small){
          font-size: $fs24;
        }
      }
    }
  }
}